import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import ContractsContext from 'contexts/contracts';

function useSignerAddressQuery(): {
  data: string;
} {
  const { provider } = useContext(ContractsContext);

  const { data = '', ...values } = useQuery({
    queryKey: ['SIGNER_ADDRESS'],
    queryFn: async () => {
      const [account] = await provider.listAccounts();

      if (account) {
        const signer = await provider.getSigner();
        const address = signer.address;

        return address;
      }

      return '';
    },
    retry: false,
  });

  return {
    data,
    ...values,
  };
}

export default useSignerAddressQuery;
