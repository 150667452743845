function getEnv(envVar: string, fallback: string) {
  return process.env[envVar] || fallback;
}

// function getbooleanEnv(envVar, fallback) {
//   const value = env[envVar];
//
//   if (!value) return fallback;
//   if (value === 'false' || value === 'FALSE') return false;
//   if (value === 'true' || value === 'TRUE') return true;
//
//   return value;
// }

export const escrowContractAddressLookup = {
  mainnet: getEnv('REACT_APP_MAINNET_ESCROW_CONTRACT_ADDRESS', ''),
  goerli: getEnv('REACT_APP_GOERLI_ESCROW_CONTRACT_ADDRESS', ''),
  hardhat: getEnv('REACT_APP_HARDHAT_ESCROW_CONTRACT_ADDRESS', ''),
};

export const wethTokenAddressLookup = {
  mainnet: getEnv('REACT_APP_MAINNET_WETH_TOKEN_ADDRESS', ''),
  goerli: getEnv('REACT_APP_GOERLI_WETH_TOKEN_ADDRESS', ''),
  hardhat: getEnv('REACT_APP_HARDHAT_WETH_TOKEN_ADDRESS', ''),
};

export const usdtTokenAddressLookup = {
  mainnet: getEnv('REACT_APP_MAINNET_USDT_TOKEN_ADDRESS', ''),
  goerli: getEnv('REACT_APP_GOERLI_USDT_TOKEN_ADDRESS', ''),
  hardhat: getEnv('REACT_APP_HARDHAT_USDT_TOKEN_ADDRESS', ''),
};

export const lithTokenAddressLookup = {
  mainnet: getEnv('REACT_APP_MAINNET_LITH_TOKEN_ADDRESS', ''),
  goerli: getEnv('REACT_APP_GOERLI_LITH_TOKEN_ADDRESS', ''),
  hardhat: getEnv('REACT_APP_HARDHAT_LITH_TOKEN_ADDRESS', ''),
};

const main = {
  blockchainNetwork: getEnv('REACT_APP_BLOCKCHAIN_NETWORK', 'hardhat'),
  appEnvironment: getEnv('REACT_APP_APP_ENVIRONMENT', 'development'),
  baseUrl: getEnv('REACT_APP_BASE_URL', ''),
};

const contract = {
  escrowContractAddress:
    escrowContractAddressLookup[process.env.REACT_APP_BLOCKCHAIN_NETWORK],
  wethTokenAddress:
    wethTokenAddressLookup[process.env.REACT_APP_BLOCKCHAIN_NETWORK],
  usdtTokenAddress:
    usdtTokenAddressLookup[process.env.REACT_APP_BLOCKCHAIN_NETWORK],
  lithTokenAddress:
    lithTokenAddressLookup[process.env.REACT_APP_BLOCKCHAIN_NETWORK],
};

const services = {
  infuraId: getEnv('REACT_APP_INFURA_ID', ''),
  infuraSecret: getEnv('REACT_APP_INFURA_SECRET', ''),
  openSeaApiKey: getEnv('REACT_APP_OPEN_SEA_API_KEY', ''),
  openSeaBaseUrl: getEnv(
    'REACT_APP_OPEN_SEA_BASE_URL',
    'https://testnets-api.opensea.io/api/v1'
  ),
  bugSnagApiKey: getEnv('REACT_APP_BUG_SNAG_API_KEY', ''),
  mixpanelProjectToken: getEnv('REACT_APP_MIXPANEL_PROJECT_TOKEN', ''),
};

export default {
  main,
  contract,
  services,
};
