import { message } from 'antd';
import { wait } from 'utils';
// import type { BrowserProvider } from 'ethers/types/providers/provider-browser.d.ts';
// import { chooseProvider } from 'smart-contracts';

async function login() {
  // const provider = await chooseProvider()
  const provider = await window.ethereum;
  const [address] = await provider.request({ method: 'eth_requestAccounts' });

  message.success(`Connected MetaMask with ${address}!`);
  await wait(1000);
  // window.location.reload();
  return address;
}

async function logout() {}

export { login, logout };
