import { CloseCircleOutlined } from '@ant-design/icons';
import { Base64 } from 'js-base64';
import { ethers, parseEther } from 'ethers';
import { useContext, useState, KeyboardEvent } from 'react';
import { Space, message } from 'antd';
import { dayjs } from 'libs/dayjs';
import {
  TOKEN_TYPE,
  ESCROW_CONTRACT_AGREEMENT_TYPE,
} from 'smart-contracts/constants';
import { chooseContractAddress, chooseAbi } from 'smart-contracts';
import _ from 'lodash';
import ContractsContext from 'contexts/contracts';
import mixpanel from 'libs/mixpanel';

// queries
import useSignerAddressQuery from 'hooks/use-signer-address-query';
import useOpenSeaAddressQuery from 'hooks/use-opensea-address-query';

// components
import AssetPreview from 'components/AssetPreview';
import AssetSelection from 'components/AssetSelection';

export interface IAsset {
  tokenId: number;
  address: string;
  amount: number;
  tokenType: 'ERC721' | 'ERC1155';
  imgUrl: string;
  collectionName: string;
}

export interface IToken {
  address?: string;
  amount?: string;
}

function Escrow({
  setSwapCode,
  swapCode,
}: {
  setSwapCode: Function;
  swapCode: string | null;
}) {
  const [selectedAssets, setSelectedAssets] = useState<IAsset[]>([]);
  const [selectedToken, setSelectedToken] = useState<IToken | null>(null);
  const [oldAssets, setOldAssets] = useState<IAsset[]>([]);

  const [othersAddress, setOthersAddress] = useState<string | null>(null);
  const [othersSelectedAssets, setOthersSelectedAssets] = useState<IAsset[]>(
    []
  );
  const [othersOldAssets, setOthersOldAssets] = useState<IAsset[]>([]);
  const [othersSelectedToken, setOthersSelectedToken] = useState<IToken | null>(
    null
  );
  const [isOthersAddressEditable, setIsOthersAddressEditable] =
    useState<boolean>(true);

  const [mode, setMode] = useState<string>('DEFAULT');
  const [inputAddress, setInputAddress] = useState<string>('');

  const [expiration, setExpiration] = useState<number | null>(null);
  const [swapSignature, setSwapSignature] = useState<string | null>(null);

  const { data: address } = useSignerAddressQuery();
  const {
    data: { assets },
  } = useOpenSeaAddressQuery({ address });
  const {
    data: { assets: othersAssets },
  } = useOpenSeaAddressQuery({ address: othersAddress });

  const { provider, escrowContractRead } = useContext(ContractsContext);

  async function onCreateSwapClicked() {
    const signer = await provider.getSigner();
    const nonce = (
      await escrowContractRead.getNonce(signer.address, othersAddress)
    ).toString();
    const expiryTimestamp = dayjs().add(24, 'hours').unix();
    setExpiration(expiryTimestamp);

    // signer
    const signerAssets: any = selectedAssets.map(
      ({ address, tokenId, tokenType, imgUrl, collectionName }) => {
        return {
          address,
          tokenId,
          tokenType,
          imgUrl,
          collectionName,
          amount: 1,
        };
      }
    );

    let allSignerAssets = signerAssets;
    if (!_.isEmpty(selectedToken) && selectedToken.amount) {
      allSignerAssets = [
        ...allSignerAssets,
        {
          address: selectedToken.address,
          tokenId: 0,
          tokenType: 'ERC20',
          amount: parseEther(selectedToken.amount).toString(),
        },
      ];
    }

    // executor
    const executorAssets: any = othersSelectedAssets.map(
      ({ address, tokenId, tokenType, imgUrl, collectionName }) => {
        return {
          address,
          tokenId,
          tokenType,
          imgUrl,
          collectionName,
          amount: 1,
        };
      }
    );

    let allExecutorAssets = executorAssets;
    if (!_.isEmpty(othersSelectedToken) && othersSelectedToken.amount) {
      allExecutorAssets = [
        ...allExecutorAssets,
        {
          address: othersSelectedToken.address,
          tokenId: 0,
          tokenType: 'ERC20',
          amount: parseEther(othersSelectedToken.amount).toString(),
        },
      ];
    }

    const unsignedData = {
      nonce,
      signerSide: {
        trader: signer.address,
        tokenType: allSignerAssets.map((a: IAsset) => TOKEN_TYPE[a.tokenType]),
        tokenAddress: allSignerAssets.map((a: IAsset) => a.address),
        tokenId: allSignerAssets.map((a: IAsset) => a.tokenId),
        tokenAmount: allSignerAssets.map((a: IAsset) => a.amount),
      },
      executorSide: {
        trader: othersAddress,
        tokenType: allExecutorAssets.map(
          (a: IAsset) => TOKEN_TYPE[a.tokenType]
        ),
        tokenAddress: allExecutorAssets.map((a: IAsset) => a.address),
        tokenId: allExecutorAssets.map((a: IAsset) => a.tokenId),
        tokenAmount: allExecutorAssets.map((a: IAsset) => a.amount),
      },
      expiryTimestamp,
    };

    const domain = {
      name: 'Escrow',
      version: '1',
      chainId: (await provider.getNetwork()).chainId,
      verifyingContract: chooseContractAddress('ESCROW_CONTRACT'),
    };

    const signature = await signer.signTypedData(
      domain,
      ESCROW_CONTRACT_AGREEMENT_TYPE,
      unsignedData
    );
    setSwapSignature(signature);

    for (let i = 0; i < allSignerAssets.length; i++) {
      const asset = allSignerAssets[i];

      if (asset.tokenType === 'ERC721') {
        const contract = new ethers.Contract(
          asset.address,
          chooseAbi('ERC721'),
          signer
        );

        const isApproved = await contract.isApprovedForAll(
          signer.address,
          chooseContractAddress('ESCROW_CONTRACT')
        );

        if (!isApproved) {
          const approvalTx = await contract.setApprovalForAll(
            chooseContractAddress('ESCROW_CONTRACT'),
            true
          );
          message.loading({
            key: '721-approval-pending',
            content: 'Approval pending on chain...',
            duration: 0,
          });

          const receipt = await approvalTx.wait();

          message.destroy('721-approval-pending');
          message.success({
            duration: 5,
            content: (
              <div>
                Approval successful, see transaction {`${receipt.hash}`}
              </div>
            ),
          });
        }
      }

      if (asset.tokenType === 'ERC1155') {
        const contract = new ethers.Contract(
          asset.address,
          chooseAbi('ERC1155'),
          signer
        );

        const isApproved = await contract.isApprovedForAll(
          signer.address,
          chooseContractAddress('ESCROW_CONTRACT')
        );

        if (!isApproved) {
          const approvalTx = await contract.setApprovalForAll(
            chooseContractAddress('ESCROW_CONTRACT'),
            true
          );

          message.loading({
            key: '1155-approval-pending',
            content: 'Approval pending on chain...',
            duration: 0,
          });

          const receipt = await approvalTx.wait();

          message.destroy('1155-approval-pending');
          message.success({
            duration: 5,
            content: (
              <div>
                Approval successful, see transaction {`${receipt.hash}`}
              </div>
            ),
          });
        }
      }

      if (asset.tokenType === 'ERC20') {
        const contract = new ethers.Contract(
          asset.address,
          chooseAbi('ERC20'),
          signer
        );

        const allowance = await contract.allowance(
          signer.address,
          chooseContractAddress('ESCROW_CONTRACT')
        );

        if (asset.amount > allowance) {
          const approvalTx = await contract.approve(
            chooseContractAddress('ESCROW_CONTRACT'),
            ethers.MaxUint256
          );

          message.loading({
            key: '20-approval-pending',
            content: 'Approval pending on chain...',
            duration: 0,
          });

          const receipt = await approvalTx.wait();

          message.destroy('20-approval-pending');
          message.success({
            duration: 5,
            content: (
              <div>
                Approval successful, see transaction {`${receipt.hash}`}
              </div>
            ),
          });
        }
      }
    }

    const unencodedData = {
      nonce,
      signerSide: {
        trader: signer.address,
        tokenType: allSignerAssets.map((a: IAsset) => TOKEN_TYPE[a.tokenType]),
        tokenAddress: allSignerAssets.map((a: IAsset) => a.address),
        tokenId: allSignerAssets.map((a: IAsset) => a.tokenId),
        tokenAmount: allSignerAssets.map((a: IAsset) => a.amount),
        tokenImgUrl: allSignerAssets.map((a: IAsset) => a.imgUrl),
        tokenCollectionName: allSignerAssets.map(
          (a: IAsset) => a.collectionName
        ),
      },
      executorSide: {
        trader: othersAddress,
        tokenType: allExecutorAssets.map(
          (a: IAsset) => TOKEN_TYPE[a.tokenType]
        ),
        tokenAddress: allExecutorAssets.map((a: IAsset) => a.address),
        tokenId: allExecutorAssets.map((a: IAsset) => a.tokenId),
        tokenAmount: allExecutorAssets.map((a: IAsset) => a.amount),
        tokenImgUrl: allExecutorAssets.map((a: IAsset) => a.imgUrl),
        tokenCollectionName: allExecutorAssets.map(
          (a: IAsset) => a.collectionName
        ),
      },
      expiryTimestamp,
    };

    const encoded = Base64.encode(
      JSON.stringify({
        unencodedData,
        nonce,
        expiryTimestamp,
        signature,
      })
    );

    setSwapCode(encoded);
    mixpanel.track('USER_CREATED_SWAP');

    const section = document.querySelector('#swap-code');
    section && section.scrollIntoView({ behavior: 'smooth', block: 'start' });

    message.success('SwapCode created. Please copy and save the SwapCode');
  }

  async function onNFTClicked({
    tokenId,
    address,
    amount,
    tokenType,
    imgUrl,
    collectionName,
    oldAssetsState,
    setFn,
  }: {
    tokenId: number;
    address: string;
    amount: number | string;
    tokenType: string;
    imgUrl: string;
    collectionName: string;
    oldAssetsState: IAsset[];
    setFn: Function;
  }) {
    let updatedAssets;

    const found = oldAssetsState.find((a) => {
      return a.tokenId === tokenId && a.address === address;
    });

    if (found) {
      updatedAssets = _.reject(oldAssetsState, (a) => {
        return a.tokenId === tokenId && a.address === address;
      });
    } else {
      // max of 5 NFTs can be selected
      if (oldAssetsState.length <= 4) {
        updatedAssets = [
          ...oldAssetsState,
          {
            tokenId,
            address,
            amount,
            tokenType,
            imgUrl,
            collectionName,
          },
        ];
      } else {
        updatedAssets = oldAssetsState;
        message.error('You can only select a max. of 5 NFTs');
      }
    }

    setFn(updatedAssets);
  }

  async function onSearchClicked() {
    if (_.toLower(inputAddress) === _.toLower(address)) {
      return message.error('You cannot create a swap with yourself');
    }

    const regex = /^0x[a-fA-F0-9]{40}$/;
    if (inputAddress.match(regex)) {
      setOthersAddress(inputAddress);
      setIsOthersAddressEditable(false);
      return setMode('SELECT_OTHER_ASSETS');
    } else {
      return message.error('Please enter a valid counterparty wallet address');
    }
  }

  function onEditOthersAddressClicked() {
    setOthersAddress(null);
    setIsOthersAddressEditable(true);
    setOthersOldAssets([]);
    setOthersSelectedToken(null);
    setOthersSelectedAssets([]);
  }

  function onSearchKeyPressed(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      onSearchClicked();
    }
  }

  return (
    <Space
      direction="vertical"
      size={16}
      className="w-[942px] bg-black p-[50px]"
      id="escrow"
    >
      <div className={`${mode !== 'DEFAULT' ? 'hidden' : ''}`}>
        <div className="uppercase text-3xl font-bold text-[#FCF951]">
          You Pay
        </div>
        <div className="text-white text-lg my-[10px]">
          Add at least 1 NFT and/or ERC20 Token to the SWAP requirement.
        </div>
        <AssetPreview
          address={address}
          assets={assets}
          selectedAssets={selectedAssets}
          setFn={setSelectedAssets}
          onNFTClicked={onNFTClicked}
          setSelectedToken={setSelectedToken}
          selectedToken={selectedToken}
          mode={mode}
          setMode={() => {
            setOldAssets(selectedAssets);
            setMode('SELECT_YOUR_ASSETS');
          }}
          isDisabled={false}
          isLoggedIn={!_.isEmpty(address)}
        />
      </div>
      {mode === 'SELECT_YOUR_ASSETS' && assets && (
        <AssetSelection
          assets={assets}
          selectedAssets={selectedAssets}
          oldAssets={oldAssets}
          setFn={setSelectedAssets}
          onNFTClicked={onNFTClicked}
          setSelectedToken={setSelectedToken}
          selectedToken={selectedToken}
          setMode={setMode}
        />
      )}

      <div className={`${mode !== 'DEFAULT' ? 'hidden' : ''}`}>
        <div className="uppercase text-3xl font-bold text-[#FCF951] mt-[40px]">
          You Receive
        </div>
        <div className="my-[10px] text-white text-lg">
          Your counterparty address
        </div>

        <div className="flex items-center">
          {isOthersAddressEditable ? (
            <>
              <input
                className="h-[42px] bg-black border-4 text-[#7C7A00] border-[#FCF952] px-[12px] py-[7px] w-[400px]"
                placeholder="Wallet address"
                onChange={(e) => setInputAddress(e.target.value)}
                onKeyPress={onSearchKeyPressed}
              />
              <button
                onClick={onSearchClicked}
                className="h-[42px] text-black font-bold px-[30px] py-[5px] bg-[#FCF952] flex justify-center items-center border-[6px] border-[#FCF952]"
              >
                Search
              </button>
            </>
          ) : (
            <div className="bg-[#FCF952] w-[480px] flex justify-between font-bold text-black px-[15px] py-[7px] items-center">
              {othersAddress}
              <CloseCircleOutlined
                className="text-[15px] cursor-pointer"
                onClick={onEditOthersAddressClicked}
              />
            </div>
          )}
        </div>
        <div className="text-white text-lg my-[10px]">
          Add at least 1 NFT and/or ERC20 Token to the SWAP requirement.
        </div>

        <AssetPreview
          address={othersAddress}
          assets={othersAssets}
          selectedAssets={othersSelectedAssets}
          setFn={setOthersSelectedAssets}
          onNFTClicked={onNFTClicked}
          setSelectedToken={setOthersSelectedToken}
          selectedToken={othersSelectedToken}
          mode={mode}
          setMode={() => {
            setOthersOldAssets(othersSelectedAssets);
            setMode('SELECT_OTHER_ASSETS');
          }}
          isDisabled={!othersAddress}
          isLoggedIn={!_.isEmpty(address)}
        />

        {swapCode ? (
          <div className="mt-[40px]">
            <div className="uppercase text-3xl font-bold text-[#FCF951]">
              Details
            </div>
            <div className="flex mt-[20px]">
              <div className="mr-[200px]">
                <div className="text-[18px] text-white">
                  Transaction will expire on
                </div>
                <div className="text-[24px] font-bold text-[#FCF952]">
                  {dayjs(parseInt(`${expiration}000`, 10)).toISOString()}
                </div>
              </div>
              <div>
                <div className="text-[18px] text-white">Signature</div>
                <div className="text-[24px] font-bold text-[#FCF952] break-all">
                  {swapSignature}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center mt-[60px] items-center">
            <button
              className="text-black bg-[#FCF952] py-[10px] px-[100px] text-[18px] font-bold"
              onClick={onCreateSwapClicked}
              disabled={
                (_.isEmpty(selectedAssets) && _.isEmpty(selectedToken)) ||
                (_.isEmpty(othersSelectedAssets) &&
                  _.isEmpty(othersSelectedToken))
              }
            >
              Create a SWAP
            </button>
            <div className="text-center text-[14px] text-white mt-[10px]">
              <p>SWAP code expires in 24 hours after creation</p>
              <p className="mt-[40px] text-[12px]">
                You agree to the terms & conditions set out by PEASY Labs by
                clicking "Create a SWAP" or "Complete SWAP". Users are
                responsible for exercising caution and conducting due diligence
                before engaging in any transaction with their counterparts.
                PEASY Labs is not liable for any losses in the exchange of
                assets. Please be aware of scams and carefully check the asset's
                contract address before exchanging.
              </p>
            </div>
          </div>
        )}
      </div>
      {mode === 'SELECT_OTHER_ASSETS' && othersAddress && (
        <AssetSelection
          assets={othersAssets}
          selectedAssets={othersSelectedAssets}
          oldAssets={othersOldAssets}
          setFn={setOthersSelectedAssets}
          onNFTClicked={onNFTClicked}
          setSelectedToken={setOthersSelectedToken}
          selectedToken={othersSelectedToken}
          setMode={setMode}
        />
      )}
    </Space>
  );
}

export default Escrow;
