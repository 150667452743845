// enquiry pool abi
import escrowContractAbi from 'smart-contracts/abis/escrow-contract/2023-05-16.json';
import ierc1155Abi from 'smart-contracts/abis/IERC1155.json';
import ierc721Abi from 'smart-contracts/abis/IERC721.json';
import ierc20Abi from 'smart-contracts/abis/IERC20.json';
import ierc20MintAbi from 'smart-contracts/abis/IERC20_MINT.json';
import envVars, { escrowContractAddressLookup } from 'config';

const {
  contract: { escrowContractAddress },
} = envVars;

export const allAbiLookup = {
  // Enquiry Pool
  // staging
  [escrowContractAddressLookup.goerli]: escrowContractAbi,

  // production
  [escrowContractAddressLookup.mainnet]: escrowContractAbi,

  [escrowContractAddressLookup.hardhat]: escrowContractAbi,
};

export const abiLookup: {
  [contractName: string]: any;
} = {
  ESCROW_CONTRACT: allAbiLookup[escrowContractAddress],
  ERC1155: ierc1155Abi,
  ERC721: ierc721Abi,
  ERC20: ierc20Abi,

  ERC20_MINT: ierc20MintAbi, // testing with mint function
};
