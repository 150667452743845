import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import envVars from 'config';

const {
  services: { openSeaApiKey, openSeaBaseUrl },
  main: { blockchainNetwork },
} = envVars;

const mockData = {
  next: null,
  previous: null,
  assets: [
    {
      id: 387685645,
      token_id: '1',
      num_sales: 2,
      background_color: null,
      image_url:
        'https://i.seadn.io/gcs/files/5c7c6387232cbbd389c123e93b59a8a6.png?w=500&auto=format',
      image_preview_url:
        'https://i.seadn.io/gcs/files/5c7c6387232cbbd389c123e93b59a8a6.png?w=500&auto=format',
      image_thumbnail_url:
        'https://i.seadn.io/gcs/files/5c7c6387232cbbd389c123e93b59a8a6.png?w=500&auto=format',
      image_original_url: 'https://meta.monkeykingdom.io/3/1392.png',
      animation_url: null,
      animation_original_url: null,
      name: 'Monkey Legends #1392',
      description:
        "Monkey Legends' avatars feature traits and powers based on Sun Wukong the legendary mythical figure from traditional folklore. The Monkey Legends are designed to be Metaverse ready and fully customizable with various exclusive NFT-based collaborations. This next chapter launched by Monkey Kingdom on ETH Blockchain will support whole new experiences and exclusive access into the Metaverse.",
      external_link: 'https://monkeykingdom.io/legends/1392',
      asset_contract: {
        address: '0x1056c6Ff1169E67dDb6433C15717B96aAe1F5FFA',
        asset_contract_type: 'non-fungible',
        chain_identifier: 'ethereum',
        created_date: '2022-03-30T11:30:09.532313',
        name: 'Monkey Legends',
        nft_version: null,
        opensea_version: null,
        owner: 276423440,
        schema_name: 'ERC721',
        symbol: 'MKL',
        total_supply: '6572',
        description:
          'Next Gen Legendary Avatars, by Monkey Kingdom, Metaverse ready. Welcome to the Kingdom.',
        external_link: 'http://www.monkeykingdom.io',
        image_url:
          'https://i.seadn.io/gae/ShPBrtcjeui3x82jDKAxs00RH1g6Db52Sm_61jkC1edkNOvP3Jo-oi8eimPgHHeSBg78oYain27AnkNrWHOh8-r1boMeR9ZBW6ls_A?w=500&auto=format',
        default_to_fiat: false,
        dev_buyer_fee_basis_points: 0,
        dev_seller_fee_basis_points: 500,
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: 0,
        opensea_seller_fee_basis_points: 250,
        buyer_fee_basis_points: 0,
        seller_fee_basis_points: 750,
        payout_address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
      },
      permalink:
        'https://opensea.io/assets/ethereum/0xa8ed317c5a491bbd9127fd2fce6ec7f409b40783/1392',
      collection: {
        banner_image_url:
          'https://i.seadn.io/gae/mPTV0UboMu4n_5DroVfBbzBLDLSdF2vvxzguf8KNQ2xUvnAJg1OLa_bZcWu34TRpG-xWPwTW2hEweOEHWPpiTCh-VlhJTjnqtA6d?w=500&auto=format',
        chat_url: null,
        created_date: '2022-03-30T11:30:12.766652+00:00',
        default_to_fiat: false,
        description:
          'Next Gen Legendary Avatars, by Monkey Kingdom, Metaverse ready. Welcome to the Kingdom.',
        dev_buyer_fee_basis_points: '0',
        dev_seller_fee_basis_points: '500',
        discord_url: 'https://discord.gg/monkeykingdom',
        display_data: {
          card_display_style: 'contain',
        },
        external_url: 'http://www.monkeykingdom.io',
        featured: false,
        featured_image_url:
          'https://i.seadn.io/gae/8Fl3oysB_G8wpl7e9ULBA0is1Z0KWRUyqLF48QqUZtlruFvVU6V1gXJQWarK5VklkhWuFbyoyQDRxW3vtZlwSNLLHJxnl8aKfNRI6A?w=500&auto=format',
        hidden: false,
        safelist_request_status: 'approved',
        image_url:
          'https://i.seadn.io/gae/ShPBrtcjeui3x82jDKAxs00RH1g6Db52Sm_61jkC1edkNOvP3Jo-oi8eimPgHHeSBg78oYain27AnkNrWHOh8-r1boMeR9ZBW6ls_A?w=500&auto=format',
        is_subject_to_whitelist: false,
        large_image_url:
          'https://i.seadn.io/gae/8Fl3oysB_G8wpl7e9ULBA0is1Z0KWRUyqLF48QqUZtlruFvVU6V1gXJQWarK5VklkhWuFbyoyQDRxW3vtZlwSNLLHJxnl8aKfNRI6A?w=500&auto=format',
        medium_username: null,
        name: 'Monkey Legends - Official',
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: '0',
        opensea_seller_fee_basis_points: 250,
        payout_address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
        require_email: false,
        short_description: null,
        slug: 'monkey-legends',
        telegram_url: null,
        twitter_username: null,
        instagram_username: 'monkeykingdom_',
        wiki_url: null,
        is_nsfw: false,
        fees: {
          seller_fees: {
            '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7': 500,
          },
          opensea_fees: {
            '0x0000a26b00c1f0df003000390027140000faa719': 250,
          },
        },
        is_rarity_enabled: true,
        is_creator_fees_enforced: false,
      },
      decimals: 0,
      token_metadata: 'https://meta.monkeykingdom.io/3/1392',
      is_nsfw: false,
      owner: null,
      seaport_sell_orders: null,
      creator: {
        user: {
          username: 'MonkeyLegends',
        },
        address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
        config: 'verified',
        profile_img_url: '',
      },
      traits: [
        {
          trait_type: 'Fur',
          display_type: null,
          max_value: null,
          trait_count: 684,
          order: null,
          value: 'Black',
        },
        {
          trait_type: 'Background',
          display_type: null,
          max_value: null,
          trait_count: 426,
          order: null,
          value: 'Cream',
        },
        {
          trait_type: 'Skin',
          display_type: null,
          max_value: null,
          trait_count: 796,
          order: null,
          value: 'Brown',
        },
        {
          trait_type: 'Body',
          display_type: null,
          max_value: null,
          trait_count: 250,
          order: null,
          value: 'Tie Orange',
        },
        {
          trait_type: 'Gender',
          display_type: null,
          max_value: null,
          trait_count: 5027,
          order: null,
          value: 'Male',
        },
        {
          trait_type: 'Eyes',
          display_type: null,
          max_value: null,
          trait_count: 44,
          order: null,
          value: 'Tron Yellow',
        },
        {
          trait_type: 'Face Tattoo',
          display_type: null,
          max_value: null,
          trait_count: 152,
          order: null,
          value: 'Warpaint',
        },
        {
          trait_type: 'Base',
          display_type: null,
          max_value: null,
          trait_count: 0,
          order: null,
          value: 'Common',
        },
      ],
      top_bid: null,
      listing_date: null,
      supports_wyvern: true,
      rarity_data: {
        strategy_id: 'openrarity',
        strategy_version: '0.7.1b0',
        rank: 4541,
        score: 0.902765206343272,
        calculated_at: '2023-05-11T03:42:53.068764',
        max_rank: 6572,
        tokens_scored: 6572,
        ranking_features: {
          unique_attribute_count: 0,
        },
      },
      transfer_fee: null,
      transfer_fee_payment_token: null,
    },
    {
      id: 387567964,
      token_id: '2',
      num_sales: 0,
      background_color: null,
      image_url:
        'https://i.seadn.io/gcs/files/18a2846841488c9006b1b0a51d1f526f.png?w=500&auto=format',
      image_preview_url:
        'https://i.seadn.io/gcs/files/18a2846841488c9006b1b0a51d1f526f.png?w=500&auto=format',
      image_thumbnail_url:
        'https://i.seadn.io/gcs/files/18a2846841488c9006b1b0a51d1f526f.png?w=500&auto=format',
      image_original_url: 'https://meta.monkeykingdom.io/3/30.png',
      animation_url: null,
      animation_original_url: null,
      name: 'Monkey Legends #30',
      description:
        "Monkey Legends' avatars feature traits and powers based on Sun Wukong the legendary mythical figure from traditional folklore. The Monkey Legends are designed to be Metaverse ready and fully customizable with various exclusive NFT-based collaborations. This next chapter launched by Monkey Kingdom on ETH Blockchain will support whole new experiences and exclusive access into the Metaverse.",
      external_link: 'https://monkeykingdom.io/legends/30',
      asset_contract: {
        address: '0x1056c6Ff1169E67dDb6433C15717B96aAe1F5FFA',
        asset_contract_type: 'non-fungible',
        chain_identifier: 'ethereum',
        created_date: '2022-03-30T11:30:09.532313',
        name: 'Monkey Legends',
        nft_version: null,
        opensea_version: null,
        owner: 276423440,
        schema_name: 'ERC721',
        symbol: 'MKL',
        total_supply: '6572',
        description:
          'Next Gen Legendary Avatars, by Monkey Kingdom, Metaverse ready. Welcome to the Kingdom.',
        external_link: 'http://www.monkeykingdom.io',
        image_url:
          'https://i.seadn.io/gae/ShPBrtcjeui3x82jDKAxs00RH1g6Db52Sm_61jkC1edkNOvP3Jo-oi8eimPgHHeSBg78oYain27AnkNrWHOh8-r1boMeR9ZBW6ls_A?w=500&auto=format',
        default_to_fiat: false,
        dev_buyer_fee_basis_points: 0,
        dev_seller_fee_basis_points: 500,
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: 0,
        opensea_seller_fee_basis_points: 250,
        buyer_fee_basis_points: 0,
        seller_fee_basis_points: 750,
        payout_address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
      },
      permalink:
        'https://opensea.io/assets/ethereum/0xa8ed317c5a491bbd9127fd2fce6ec7f409b40783/30',
      collection: {
        banner_image_url:
          'https://i.seadn.io/gae/mPTV0UboMu4n_5DroVfBbzBLDLSdF2vvxzguf8KNQ2xUvnAJg1OLa_bZcWu34TRpG-xWPwTW2hEweOEHWPpiTCh-VlhJTjnqtA6d?w=500&auto=format',
        chat_url: null,
        created_date: '2022-03-30T11:30:12.766652+00:00',
        default_to_fiat: false,
        description:
          'Next Gen Legendary Avatars, by Monkey Kingdom, Metaverse ready. Welcome to the Kingdom.',
        dev_buyer_fee_basis_points: '0',
        dev_seller_fee_basis_points: '500',
        discord_url: 'https://discord.gg/monkeykingdom',
        display_data: {
          card_display_style: 'contain',
        },
        external_url: 'http://www.monkeykingdom.io',
        featured: false,
        featured_image_url:
          'https://i.seadn.io/gae/8Fl3oysB_G8wpl7e9ULBA0is1Z0KWRUyqLF48QqUZtlruFvVU6V1gXJQWarK5VklkhWuFbyoyQDRxW3vtZlwSNLLHJxnl8aKfNRI6A?w=500&auto=format',
        hidden: false,
        safelist_request_status: 'approved',
        image_url:
          'https://i.seadn.io/gae/ShPBrtcjeui3x82jDKAxs00RH1g6Db52Sm_61jkC1edkNOvP3Jo-oi8eimPgHHeSBg78oYain27AnkNrWHOh8-r1boMeR9ZBW6ls_A?w=500&auto=format',
        is_subject_to_whitelist: false,
        large_image_url:
          'https://i.seadn.io/gae/8Fl3oysB_G8wpl7e9ULBA0is1Z0KWRUyqLF48QqUZtlruFvVU6V1gXJQWarK5VklkhWuFbyoyQDRxW3vtZlwSNLLHJxnl8aKfNRI6A?w=500&auto=format',
        medium_username: null,
        name: 'Monkey Legends - Official',
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: '0',
        opensea_seller_fee_basis_points: 250,
        payout_address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
        require_email: false,
        short_description: null,
        slug: 'monkey-legends',
        telegram_url: null,
        twitter_username: null,
        instagram_username: 'monkeykingdom_',
        wiki_url: null,
        is_nsfw: false,
        fees: {
          seller_fees: {
            '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7': 500,
          },
          opensea_fees: {
            '0x0000a26b00c1f0df003000390027140000faa719': 250,
          },
        },
        is_rarity_enabled: true,
        is_creator_fees_enforced: false,
      },
      decimals: 0,
      token_metadata: 'https://meta.monkeykingdom.io/3/30',
      is_nsfw: false,
      owner: null,
      seaport_sell_orders: null,
      creator: {
        user: {
          username: 'MonkeyLegends',
        },
        address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
        config: 'verified',
        profile_img_url: '',
      },
      traits: [
        {
          trait_type: 'Skin',
          display_type: null,
          max_value: null,
          trait_count: 1135,
          order: null,
          value: 'Yellow',
        },
        {
          trait_type: 'Mouth',
          display_type: null,
          max_value: null,
          trait_count: 118,
          order: null,
          value: 'Cigar',
        },
        {
          trait_type: 'Arms',
          display_type: null,
          max_value: null,
          trait_count: 85,
          order: null,
          value: 'Warpaint Blue',
        },
        {
          trait_type: 'Fur',
          display_type: null,
          max_value: null,
          trait_count: 684,
          order: null,
          value: 'Black',
        },
        {
          trait_type: 'Background',
          display_type: null,
          max_value: null,
          trait_count: 392,
          order: null,
          value: 'Grey',
        },
        {
          trait_type: 'Eyes',
          display_type: null,
          max_value: null,
          trait_count: 898,
          order: null,
          value: 'Normal',
        },
        {
          trait_type: 'Gender',
          display_type: null,
          max_value: null,
          trait_count: 5027,
          order: null,
          value: 'Male',
        },
        {
          trait_type: 'Head',
          display_type: null,
          max_value: null,
          trait_count: 12,
          order: null,
          value: 'Sunny Purple',
        },
        {
          trait_type: 'Body',
          display_type: null,
          max_value: null,
          trait_count: 51,
          order: null,
          value: 'Rockman Armor',
        },
        {
          trait_type: 'Ears',
          display_type: null,
          max_value: null,
          trait_count: 579,
          order: null,
          value: 'Gold Right',
        },
        {
          trait_type: 'Base',
          display_type: null,
          max_value: null,
          trait_count: 0,
          order: null,
          value: 'Common',
        },
      ],
      last_sale: null,
      top_bid: null,
      listing_date: null,
      supports_wyvern: true,
      rarity_data: {
        strategy_id: 'openrarity',
        strategy_version: '0.7.1b0',
        rank: 172,
        score: 1.373715941481713,
        calculated_at: '2023-05-11T03:42:53.068764',
        max_rank: 6572,
        tokens_scored: 6572,
        ranking_features: {
          unique_attribute_count: 0,
        },
      },
      transfer_fee: null,
      transfer_fee_payment_token: null,
    },
    {
      id: 288151596,
      token_id: '11',
      num_sales: 5,
      background_color: null,
      image_url:
        'https://i.seadn.io/gcs/files/14cf7b550d9798b24582f6fe3ac4992c.png?w=500&auto=format',
      image_preview_url:
        'https://i.seadn.io/gcs/files/14cf7b550d9798b24582f6fe3ac4992c.png?w=500&auto=format',
      image_thumbnail_url:
        'https://i.seadn.io/gcs/files/14cf7b550d9798b24582f6fe3ac4992c.png?w=500&auto=format',
      image_original_url:
        'ipfs://QmQnGRh1Rucip1Y9tSXgEZMmDEswhT9bsKf6EawW912pCj/09378.png',
      animation_url: null,
      animation_original_url: null,
      name: 'Croc #9378',
      description: null,
      external_link: null,
      asset_contract: {
        address: '0x0eA64aB044DF1C3aB55A71C0CAe4fF2F661F77Cc',
        asset_contract_type: 'non-fungible',
        chain_identifier: 'ethereum',
        created_date: '2021-12-22T11:11:20.083893',
        name: 'Acrocalypse',
        nft_version: null,
        opensea_version: null,
        owner: 179273130,
        schema_name: 'ERC721',
        symbol: 'ACROC',
        total_supply: '10419',
        description:
          'ACTUAL HOLDER COUNT: 3337, 50% Currently Staked\n\nAcrocalypse is the ecosystem NFT for paperhands.gg. Your Croc isn’t only a PFP collectible that will take you on missions throughout the Acrocalypse galaxy, but the key that opens the door to next-generation digital utility, ✨ good vibes ✨, future mints and more. Buckle up, Crocs!  ',
        external_link: 'http://www.acrocalypse.gg',
        image_url:
          'https://i.seadn.io/gcs/files/5ecbec80c7f79812ec7d509083cd0a28.png?w=500&auto=format',
        default_to_fiat: false,
        dev_buyer_fee_basis_points: 0,
        dev_seller_fee_basis_points: 500,
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: 0,
        opensea_seller_fee_basis_points: 250,
        buyer_fee_basis_points: 0,
        seller_fee_basis_points: 750,
        payout_address: '0x76658b90e2b607de63c9b3730b1b693d88789164',
      },
      permalink:
        'https://opensea.io/assets/ethereum/0xd73acd7f5099fdd910215dbff029185f21ffbcf0/9378',
      collection: {
        banner_image_url:
          'https://i.seadn.io/gae/Sa6oPSogrKkGelLEQhCH-jgfgzgRJ6rwpxVabXPNwxhV2r-XSmZnPEGMU9f5PatAPjigd0vroUYN71XqYkVlJxEt2rGLfd9_r4J91w?w=500&auto=format',
        chat_url: null,
        created_date: '2021-12-22T13:41:49.115824+00:00',
        default_to_fiat: false,
        description:
          'ACTUAL HOLDER COUNT: 3337, 50% Currently Staked\n\nAcrocalypse is the ecosystem NFT for paperhands.gg. Your Croc isn’t only a PFP collectible that will take you on missions throughout the Acrocalypse galaxy, but the key that opens the door to next-generation digital utility, ✨ good vibes ✨, future mints and more. Buckle up, Crocs!  ',
        dev_buyer_fee_basis_points: '0',
        dev_seller_fee_basis_points: '500',
        discord_url: 'https://discord.gg/acrocalypse',
        display_data: {
          card_display_style: 'contain',
          images: null,
        },
        external_url: 'http://www.acrocalypse.gg',
        featured: false,
        featured_image_url: null,
        hidden: false,
        safelist_request_status: 'verified',
        image_url:
          'https://i.seadn.io/gcs/files/5ecbec80c7f79812ec7d509083cd0a28.png?w=500&auto=format',
        is_subject_to_whitelist: false,
        large_image_url: null,
        medium_username: null,
        name: 'Acrocalypse',
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: '0',
        opensea_seller_fee_basis_points: 250,
        payout_address: '0x76658b90e2b607de63c9b3730b1b693d88789164',
        require_email: false,
        short_description: null,
        slug: 'acrocalypse',
        telegram_url: null,
        twitter_username: 'acrocalypseNFT',
        instagram_username: 'acrocalypsenft',
        wiki_url: null,
        is_nsfw: false,
        fees: {
          seller_fees: {
            '0x76658b90e2b607de63c9b3730b1b693d88789164': 500,
          },
          opensea_fees: {
            '0x0000a26b00c1f0df003000390027140000faa719': 250,
          },
        },
        is_rarity_enabled: false,
        is_creator_fees_enforced: false,
      },
      decimals: 0,
      token_metadata:
        'ipfs://QmbEvQcsUzLdosWJpNXeCWxMYgfd595P9BRDWEqzAUuVQu/9378.json',
      is_nsfw: false,
      owner: null,
      seaport_sell_orders: null,
      creator: {
        user: {
          username: 'AcrocalypseDeployer',
        },
        address: '0xf84802583c474deac94ab12a396021247042d8b4',
        config: 'verified',
        profile_img_url: '',
      },
      traits: [
        {
          trait_type: 'Mouth',
          display_type: null,
          max_value: null,
          trait_count: 370,
          order: null,
          value: 'Pizza',
        },
        {
          trait_type: 'Background',
          display_type: null,
          max_value: null,
          trait_count: 634,
          order: null,
          value: 'Gradient 2',
        },
        {
          trait_type: 'DNA',
          display_type: null,
          max_value: null,
          trait_count: 207,
          order: null,
          value: 'Lava',
        },
        {
          trait_type: 'Eyes',
          display_type: null,
          max_value: null,
          trait_count: 802,
          order: null,
          value: 'Doubt',
        },
        {
          trait_type: 'Clothing',
          display_type: null,
          max_value: null,
          trait_count: 5505,
          order: null,
          value: 'none',
        },
        {
          trait_type: 'Drone',
          display_type: null,
          max_value: null,
          trait_count: 636,
          order: null,
          value: 'Deeze',
        },
        {
          trait_type: 'Eyewear',
          display_type: null,
          max_value: null,
          trait_count: 284,
          order: null,
          value: 'Purple Sunglasses',
        },
        {
          trait_type: 'Head',
          display_type: null,
          max_value: null,
          trait_count: 7032,
          order: null,
          value: 'none',
        },
      ],
      last_sale: {
        asset: {
          token_id: '9378',
          decimals: 0,
        },
        asset_bundle: null,
        event_type: 'successful',
        event_timestamp: '2022-03-22T16:01:06',
        auction_type: null,
        total_price: '980000000000000000',
        payment_token: {
          symbol: 'ETH',
          address: '0x0000000000000000000000000000000000000000',
          image_url:
            'https://openseauserdata.com/files/6f8e2979d428180222796ff4a33ab929.svg',
          name: 'Ether',
          decimals: 18,
          eth_price: '1.000000000000000',
          usd_price: '1824.400000000000091000',
        },
        transaction: {
          block_hash:
            '0xbaf024a77e4ef46bd6e1fd121e29a1fb548cfa0eb0016e243b50bb876de84b5d',
          block_number: '14437029',
          from_account: {
            user: {
              username: null,
            },
            profile_img_url:
              'https://storage.googleapis.com/opensea-static/opensea-profile/27.png',
            address: '0x87479d0f5372f270bfd1a51593b8318595659541',
            config: '',
          },
          id: 307239168,
          timestamp: '2022-03-22T16:01:06',
          to_account: {
            user: null,
            profile_img_url:
              'https://storage.googleapis.com/opensea-static/opensea-profile/28.png',
            address: '0x7f268357a8c2552623316e2562d90e642bb538e5',
            config: '',
          },
          transaction_hash:
            '0xfa27199c7661cc0cd4bf10932692084497705b90d872a6e8e67bcde3e817ebc4',
          transaction_index: '210',
        },
        created_date: '2022-03-22T16:01:22.210236',
        quantity: '1',
      },
      top_bid: null,
      listing_date: null,
      supports_wyvern: true,
      rarity_data: null,
      transfer_fee: null,
      transfer_fee_payment_token: null,
    },
    {
      id: 387685640,
      token_id: '99',
      num_sales: 2,
      background_color: null,
      image_url:
        'https://i.seadn.io/gcs/files/5c7c6387232cbbd389c123e93b59a8a6.png?w=500&auto=format',
      image_preview_url:
        'https://i.seadn.io/gcs/files/5c7c6387232cbbd389c123e93b59a8a6.png?w=500&auto=format',
      image_thumbnail_url:
        'https://i.seadn.io/gcs/files/5c7c6387232cbbd389c123e93b59a8a6.png?w=500&auto=format',
      image_original_url: 'https://meta.monkeykingdom.io/3/1392.png',
      animation_url: null,
      animation_original_url: null,
      name: 'Monkey Legends #1392',
      description:
        "Monkey Legends' avatars feature traits and powers based on Sun Wukong the legendary mythical figure from traditional folklore. The Monkey Legends are designed to be Metaverse ready and fully customizable with various exclusive NFT-based collaborations. This next chapter launched by Monkey Kingdom on ETH Blockchain will support whole new experiences and exclusive access into the Metaverse.",
      external_link: 'https://monkeykingdom.io/legends/1392',
      asset_contract: {
        address: '0x1056c6Ff1169E67dDb6433C15717B96aAe1F5FFA',
        asset_contract_type: 'non-fungible',
        chain_identifier: 'ethereum',
        created_date: '2022-03-30T11:30:09.532313',
        name: 'Monkey Legends',
        nft_version: null,
        opensea_version: null,
        owner: 276423440,
        schema_name: 'ERC721',
        symbol: 'MKL',
        total_supply: '6572',
        description:
          'Next Gen Legendary Avatars, by Monkey Kingdom, Metaverse ready. Welcome to the Kingdom.',
        external_link: 'http://www.monkeykingdom.io',
        image_url:
          'https://i.seadn.io/gae/ShPBrtcjeui3x82jDKAxs00RH1g6Db52Sm_61jkC1edkNOvP3Jo-oi8eimPgHHeSBg78oYain27AnkNrWHOh8-r1boMeR9ZBW6ls_A?w=500&auto=format',
        default_to_fiat: false,
        dev_buyer_fee_basis_points: 0,
        dev_seller_fee_basis_points: 500,
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: 0,
        opensea_seller_fee_basis_points: 250,
        buyer_fee_basis_points: 0,
        seller_fee_basis_points: 750,
        payout_address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
      },
      permalink:
        'https://opensea.io/assets/ethereum/0xa8ed317c5a491bbd9127fd2fce6ec7f409b40783/1392',
      collection: {
        banner_image_url:
          'https://i.seadn.io/gae/mPTV0UboMu4n_5DroVfBbzBLDLSdF2vvxzguf8KNQ2xUvnAJg1OLa_bZcWu34TRpG-xWPwTW2hEweOEHWPpiTCh-VlhJTjnqtA6d?w=500&auto=format',
        chat_url: null,
        created_date: '2022-03-30T11:30:12.766652+00:00',
        default_to_fiat: false,
        description:
          'Next Gen Legendary Avatars, by Monkey Kingdom, Metaverse ready. Welcome to the Kingdom.',
        dev_buyer_fee_basis_points: '0',
        dev_seller_fee_basis_points: '500',
        discord_url: 'https://discord.gg/monkeykingdom',
        display_data: {
          card_display_style: 'contain',
        },
        external_url: 'http://www.monkeykingdom.io',
        featured: false,
        featured_image_url:
          'https://i.seadn.io/gae/8Fl3oysB_G8wpl7e9ULBA0is1Z0KWRUyqLF48QqUZtlruFvVU6V1gXJQWarK5VklkhWuFbyoyQDRxW3vtZlwSNLLHJxnl8aKfNRI6A?w=500&auto=format',
        hidden: false,
        safelist_request_status: 'approved',
        image_url:
          'https://i.seadn.io/gae/ShPBrtcjeui3x82jDKAxs00RH1g6Db52Sm_61jkC1edkNOvP3Jo-oi8eimPgHHeSBg78oYain27AnkNrWHOh8-r1boMeR9ZBW6ls_A?w=500&auto=format',
        is_subject_to_whitelist: false,
        large_image_url:
          'https://i.seadn.io/gae/8Fl3oysB_G8wpl7e9ULBA0is1Z0KWRUyqLF48QqUZtlruFvVU6V1gXJQWarK5VklkhWuFbyoyQDRxW3vtZlwSNLLHJxnl8aKfNRI6A?w=500&auto=format',
        medium_username: null,
        name: 'Monkey Legends - Official',
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: '0',
        opensea_seller_fee_basis_points: 250,
        payout_address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
        require_email: false,
        short_description: null,
        slug: 'monkey-legends',
        telegram_url: null,
        twitter_username: null,
        instagram_username: 'monkeykingdom_',
        wiki_url: null,
        is_nsfw: false,
        fees: {
          seller_fees: {
            '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7': 500,
          },
          opensea_fees: {
            '0x0000a26b00c1f0df003000390027140000faa719': 250,
          },
        },
        is_rarity_enabled: true,
        is_creator_fees_enforced: false,
      },
      decimals: 0,
      token_metadata: 'https://meta.monkeykingdom.io/3/1392',
      is_nsfw: false,
      owner: null,
      seaport_sell_orders: null,
      creator: {
        user: {
          username: 'MonkeyLegends',
        },
        address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
        config: 'verified',
        profile_img_url: '',
      },
      traits: [
        {
          trait_type: 'Fur',
          display_type: null,
          max_value: null,
          trait_count: 684,
          order: null,
          value: 'Black',
        },
        {
          trait_type: 'Background',
          display_type: null,
          max_value: null,
          trait_count: 426,
          order: null,
          value: 'Cream',
        },
        {
          trait_type: 'Skin',
          display_type: null,
          max_value: null,
          trait_count: 796,
          order: null,
          value: 'Brown',
        },
        {
          trait_type: 'Body',
          display_type: null,
          max_value: null,
          trait_count: 250,
          order: null,
          value: 'Tie Orange',
        },
        {
          trait_type: 'Gender',
          display_type: null,
          max_value: null,
          trait_count: 5027,
          order: null,
          value: 'Male',
        },
        {
          trait_type: 'Eyes',
          display_type: null,
          max_value: null,
          trait_count: 44,
          order: null,
          value: 'Tron Yellow',
        },
        {
          trait_type: 'Face Tattoo',
          display_type: null,
          max_value: null,
          trait_count: 152,
          order: null,
          value: 'Warpaint',
        },
        {
          trait_type: 'Base',
          display_type: null,
          max_value: null,
          trait_count: 0,
          order: null,
          value: 'Common',
        },
      ],
      top_bid: null,
      listing_date: null,
      supports_wyvern: true,
      rarity_data: {
        strategy_id: 'openrarity',
        strategy_version: '0.7.1b0',
        rank: 4541,
        score: 0.902765206343272,
        calculated_at: '2023-05-11T03:42:53.068764',
        max_rank: 6572,
        tokens_scored: 6572,
        ranking_features: {
          unique_attribute_count: 0,
        },
      },
      transfer_fee: null,
      transfer_fee_payment_token: null,
    },
    {
      id: 387568964,
      token_id: '929',
      num_sales: 0,
      background_color: null,
      image_url:
        'https://i.seadn.io/gcs/files/18a2846841488c9006b1b0a51d1f526f.png?w=500&auto=format',
      image_preview_url:
        'https://i.seadn.io/gcs/files/18a2846841488c9006b1b0a51d1f526f.png?w=500&auto=format',
      image_thumbnail_url:
        'https://i.seadn.io/gcs/files/18a2846841488c9006b1b0a51d1f526f.png?w=500&auto=format',
      image_original_url: 'https://meta.monkeykingdom.io/3/30.png',
      animation_url: null,
      animation_original_url: null,
      name: 'Monkey Legends #30',
      description:
        "Monkey Legends' avatars feature traits and powers based on Sun Wukong the legendary mythical figure from traditional folklore. The Monkey Legends are designed to be Metaverse ready and fully customizable with various exclusive NFT-based collaborations. This next chapter launched by Monkey Kingdom on ETH Blockchain will support whole new experiences and exclusive access into the Metaverse.",
      external_link: 'https://monkeykingdom.io/legends/30',
      asset_contract: {
        address: '0x1056c6Ff1169E67dDb6433C15717B96aAe1F5FFA',
        asset_contract_type: 'non-fungible',
        chain_identifier: 'ethereum',
        created_date: '2022-03-30T11:30:09.532313',
        name: 'Monkey Legends',
        nft_version: null,
        opensea_version: null,
        owner: 276423440,
        schema_name: 'ERC721',
        symbol: 'MKL',
        total_supply: '6572',
        description:
          'Next Gen Legendary Avatars, by Monkey Kingdom, Metaverse ready. Welcome to the Kingdom.',
        external_link: 'http://www.monkeykingdom.io',
        image_url:
          'https://i.seadn.io/gae/ShPBrtcjeui3x82jDKAxs00RH1g6Db52Sm_61jkC1edkNOvP3Jo-oi8eimPgHHeSBg78oYain27AnkNrWHOh8-r1boMeR9ZBW6ls_A?w=500&auto=format',
        default_to_fiat: false,
        dev_buyer_fee_basis_points: 0,
        dev_seller_fee_basis_points: 500,
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: 0,
        opensea_seller_fee_basis_points: 250,
        buyer_fee_basis_points: 0,
        seller_fee_basis_points: 750,
        payout_address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
      },
      permalink:
        'https://opensea.io/assets/ethereum/0xa8ed317c5a491bbd9127fd2fce6ec7f409b40783/30',
      collection: {
        banner_image_url:
          'https://i.seadn.io/gae/mPTV0UboMu4n_5DroVfBbzBLDLSdF2vvxzguf8KNQ2xUvnAJg1OLa_bZcWu34TRpG-xWPwTW2hEweOEHWPpiTCh-VlhJTjnqtA6d?w=500&auto=format',
        chat_url: null,
        created_date: '2022-03-30T11:30:12.766652+00:00',
        default_to_fiat: false,
        description:
          'Next Gen Legendary Avatars, by Monkey Kingdom, Metaverse ready. Welcome to the Kingdom.',
        dev_buyer_fee_basis_points: '0',
        dev_seller_fee_basis_points: '500',
        discord_url: 'https://discord.gg/monkeykingdom',
        display_data: {
          card_display_style: 'contain',
        },
        external_url: 'http://www.monkeykingdom.io',
        featured: false,
        featured_image_url:
          'https://i.seadn.io/gae/8Fl3oysB_G8wpl7e9ULBA0is1Z0KWRUyqLF48QqUZtlruFvVU6V1gXJQWarK5VklkhWuFbyoyQDRxW3vtZlwSNLLHJxnl8aKfNRI6A?w=500&auto=format',
        hidden: false,
        safelist_request_status: 'approved',
        image_url:
          'https://i.seadn.io/gae/ShPBrtcjeui3x82jDKAxs00RH1g6Db52Sm_61jkC1edkNOvP3Jo-oi8eimPgHHeSBg78oYain27AnkNrWHOh8-r1boMeR9ZBW6ls_A?w=500&auto=format',
        is_subject_to_whitelist: false,
        large_image_url:
          'https://i.seadn.io/gae/8Fl3oysB_G8wpl7e9ULBA0is1Z0KWRUyqLF48QqUZtlruFvVU6V1gXJQWarK5VklkhWuFbyoyQDRxW3vtZlwSNLLHJxnl8aKfNRI6A?w=500&auto=format',
        medium_username: null,
        name: 'Monkey Legends - Official',
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: '0',
        opensea_seller_fee_basis_points: 250,
        payout_address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
        require_email: false,
        short_description: null,
        slug: 'monkey-legends',
        telegram_url: null,
        twitter_username: null,
        instagram_username: 'monkeykingdom_',
        wiki_url: null,
        is_nsfw: false,
        fees: {
          seller_fees: {
            '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7': 500,
          },
          opensea_fees: {
            '0x0000a26b00c1f0df003000390027140000faa719': 250,
          },
        },
        is_rarity_enabled: true,
        is_creator_fees_enforced: false,
      },
      decimals: 0,
      token_metadata: 'https://meta.monkeykingdom.io/3/30',
      is_nsfw: false,
      owner: null,
      seaport_sell_orders: null,
      creator: {
        user: {
          username: 'MonkeyLegends',
        },
        address: '0xbb5d8cf5ad489d964e49d0354b41a323c1546aa7',
        config: 'verified',
        profile_img_url: '',
      },
      traits: [
        {
          trait_type: 'Skin',
          display_type: null,
          max_value: null,
          trait_count: 1135,
          order: null,
          value: 'Yellow',
        },
        {
          trait_type: 'Mouth',
          display_type: null,
          max_value: null,
          trait_count: 118,
          order: null,
          value: 'Cigar',
        },
        {
          trait_type: 'Arms',
          display_type: null,
          max_value: null,
          trait_count: 85,
          order: null,
          value: 'Warpaint Blue',
        },
        {
          trait_type: 'Fur',
          display_type: null,
          max_value: null,
          trait_count: 684,
          order: null,
          value: 'Black',
        },
        {
          trait_type: 'Background',
          display_type: null,
          max_value: null,
          trait_count: 392,
          order: null,
          value: 'Grey',
        },
        {
          trait_type: 'Eyes',
          display_type: null,
          max_value: null,
          trait_count: 898,
          order: null,
          value: 'Normal',
        },
        {
          trait_type: 'Gender',
          display_type: null,
          max_value: null,
          trait_count: 5027,
          order: null,
          value: 'Male',
        },
        {
          trait_type: 'Head',
          display_type: null,
          max_value: null,
          trait_count: 12,
          order: null,
          value: 'Sunny Purple',
        },
        {
          trait_type: 'Body',
          display_type: null,
          max_value: null,
          trait_count: 51,
          order: null,
          value: 'Rockman Armor',
        },
        {
          trait_type: 'Ears',
          display_type: null,
          max_value: null,
          trait_count: 579,
          order: null,
          value: 'Gold Right',
        },
        {
          trait_type: 'Base',
          display_type: null,
          max_value: null,
          trait_count: 0,
          order: null,
          value: 'Common',
        },
      ],
      last_sale: null,
      top_bid: null,
      listing_date: null,
      supports_wyvern: true,
      rarity_data: {
        strategy_id: 'openrarity',
        strategy_version: '0.7.1b0',
        rank: 172,
        score: 1.373715941481713,
        calculated_at: '2023-05-11T03:42:53.068764',
        max_rank: 6572,
        tokens_scored: 6572,
        ranking_features: {
          unique_attribute_count: 0,
        },
      },
      transfer_fee: null,
      transfer_fee_payment_token: null,
    },
    {
      id: 282151598,
      token_id: '113',
      num_sales: 5,
      background_color: null,
      image_url:
        'https://i.seadn.io/gcs/files/14cf7b550d9798b24582f6fe3ac4992c.png?w=500&auto=format',
      image_preview_url:
        'https://i.seadn.io/gcs/files/14cf7b550d9798b24582f6fe3ac4992c.png?w=500&auto=format',
      image_thumbnail_url:
        'https://i.seadn.io/gcs/files/14cf7b550d9798b24582f6fe3ac4992c.png?w=500&auto=format',
      image_original_url:
        'ipfs://QmQnGRh1Rucip1Y9tSXgEZMmDEswhT9bsKf6EawW912pCj/09378.png',
      animation_url: null,
      animation_original_url: null,
      name: 'Croc #9378',
      description: null,
      external_link: null,
      asset_contract: {
        address: '0x0eA64aB044DF1C3aB55A71C0CAe4fF2F661F77Cc',
        asset_contract_type: 'non-fungible',
        chain_identifier: 'ethereum',
        created_date: '2021-12-22T11:11:20.083893',
        name: 'Acrocalypse',
        nft_version: null,
        opensea_version: null,
        owner: 179273130,
        schema_name: 'ERC721',
        symbol: 'ACROC',
        total_supply: '10419',
        description:
          'ACTUAL HOLDER COUNT: 3337, 50% Currently Staked\n\nAcrocalypse is the ecosystem NFT for paperhands.gg. Your Croc isn’t only a PFP collectible that will take you on missions throughout the Acrocalypse galaxy, but the key that opens the door to next-generation digital utility, ✨ good vibes ✨, future mints and more. Buckle up, Crocs!  ',
        external_link: 'http://www.acrocalypse.gg',
        image_url:
          'https://i.seadn.io/gcs/files/5ecbec80c7f79812ec7d509083cd0a28.png?w=500&auto=format',
        default_to_fiat: false,
        dev_buyer_fee_basis_points: 0,
        dev_seller_fee_basis_points: 500,
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: 0,
        opensea_seller_fee_basis_points: 250,
        buyer_fee_basis_points: 0,
        seller_fee_basis_points: 750,
        payout_address: '0x76658b90e2b607de63c9b3730b1b693d88789164',
      },
      permalink:
        'https://opensea.io/assets/ethereum/0xd73acd7f5099fdd910215dbff029185f21ffbcf0/9378',
      collection: {
        banner_image_url:
          'https://i.seadn.io/gae/Sa6oPSogrKkGelLEQhCH-jgfgzgRJ6rwpxVabXPNwxhV2r-XSmZnPEGMU9f5PatAPjigd0vroUYN71XqYkVlJxEt2rGLfd9_r4J91w?w=500&auto=format',
        chat_url: null,
        created_date: '2021-12-22T13:41:49.115824+00:00',
        default_to_fiat: false,
        description:
          'ACTUAL HOLDER COUNT: 3337, 50% Currently Staked\n\nAcrocalypse is the ecosystem NFT for paperhands.gg. Your Croc isn’t only a PFP collectible that will take you on missions throughout the Acrocalypse galaxy, but the key that opens the door to next-generation digital utility, ✨ good vibes ✨, future mints and more. Buckle up, Crocs!  ',
        dev_buyer_fee_basis_points: '0',
        dev_seller_fee_basis_points: '500',
        discord_url: 'https://discord.gg/acrocalypse',
        display_data: {
          card_display_style: 'contain',
          images: null,
        },
        external_url: 'http://www.acrocalypse.gg',
        featured: false,
        featured_image_url: null,
        hidden: false,
        safelist_request_status: 'verified',
        image_url:
          'https://i.seadn.io/gcs/files/5ecbec80c7f79812ec7d509083cd0a28.png?w=500&auto=format',
        is_subject_to_whitelist: false,
        large_image_url: null,
        medium_username: null,
        name: 'Acrocalypse',
        only_proxied_transfers: false,
        opensea_buyer_fee_basis_points: '0',
        opensea_seller_fee_basis_points: 250,
        payout_address: '0x76658b90e2b607de63c9b3730b1b693d88789164',
        require_email: false,
        short_description: null,
        slug: 'acrocalypse',
        telegram_url: null,
        twitter_username: 'acrocalypseNFT',
        instagram_username: 'acrocalypsenft',
        wiki_url: null,
        is_nsfw: false,
        fees: {
          seller_fees: {
            '0x76658b90e2b607de63c9b3730b1b693d88789164': 500,
          },
          opensea_fees: {
            '0x0000a26b00c1f0df003000390027140000faa719': 250,
          },
        },
        is_rarity_enabled: false,
        is_creator_fees_enforced: false,
      },
      decimals: 0,
      token_metadata:
        'ipfs://QmbEvQcsUzLdosWJpNXeCWxMYgfd595P9BRDWEqzAUuVQu/9378.json',
      is_nsfw: false,
      owner: null,
      seaport_sell_orders: null,
      creator: {
        user: {
          username: 'AcrocalypseDeployer',
        },
        address: '0xf84802583c474deac94ab12a396021247042d8b4',
        config: 'verified',
        profile_img_url: '',
      },
      traits: [
        {
          trait_type: 'Mouth',
          display_type: null,
          max_value: null,
          trait_count: 370,
          order: null,
          value: 'Pizza',
        },
        {
          trait_type: 'Background',
          display_type: null,
          max_value: null,
          trait_count: 634,
          order: null,
          value: 'Gradient 2',
        },
        {
          trait_type: 'DNA',
          display_type: null,
          max_value: null,
          trait_count: 207,
          order: null,
          value: 'Lava',
        },
        {
          trait_type: 'Eyes',
          display_type: null,
          max_value: null,
          trait_count: 802,
          order: null,
          value: 'Doubt',
        },
        {
          trait_type: 'Clothing',
          display_type: null,
          max_value: null,
          trait_count: 5505,
          order: null,
          value: 'none',
        },
        {
          trait_type: 'Drone',
          display_type: null,
          max_value: null,
          trait_count: 636,
          order: null,
          value: 'Deeze',
        },
        {
          trait_type: 'Eyewear',
          display_type: null,
          max_value: null,
          trait_count: 284,
          order: null,
          value: 'Purple Sunglasses',
        },
        {
          trait_type: 'Head',
          display_type: null,
          max_value: null,
          trait_count: 7032,
          order: null,
          value: 'none',
        },
      ],
      last_sale: {
        asset: {
          token_id: '9378',
          decimals: 0,
        },
        asset_bundle: null,
        event_type: 'successful',
        event_timestamp: '2022-03-22T16:01:06',
        auction_type: null,
        total_price: '980000000000000000',
        payment_token: {
          symbol: 'ETH',
          address: '0x0000000000000000000000000000000000000000',
          image_url:
            'https://openseauserdata.com/files/6f8e2979d428180222796ff4a33ab929.svg',
          name: 'Ether',
          decimals: 18,
          eth_price: '1.000000000000000',
          usd_price: '1824.400000000000091000',
        },
        transaction: {
          block_hash:
            '0xbaf024a77e4ef46bd6e1fd121e29a1fb548cfa0eb0016e243b50bb876de84b5d',
          block_number: '14437029',
          from_account: {
            user: {
              username: null,
            },
            profile_img_url:
              'https://storage.googleapis.com/opensea-static/opensea-profile/27.png',
            address: '0x87479d0f5372f270bfd1a51593b8318595659541',
            config: '',
          },
          id: 307239168,
          timestamp: '2022-03-22T16:01:06',
          to_account: {
            user: null,
            profile_img_url:
              'https://storage.googleapis.com/opensea-static/opensea-profile/28.png',
            address: '0x7f268357a8c2552623316e2562d90e642bb538e5',
            config: '',
          },
          transaction_hash:
            '0xfa27199c7661cc0cd4bf10932692084497705b90d872a6e8e67bcde3e817ebc4',
          transaction_index: '210',
        },
        created_date: '2022-03-22T16:01:22.210236',
        quantity: '1',
      },
      top_bid: null,
      listing_date: null,
      supports_wyvern: true,
      rarity_data: null,
      transfer_fee: null,
      transfer_fee_payment_token: null,
    },
  ],
};

function useOpenSeaAddressQuery({ address }: { address: string | null }) {
  const { data = {}, ...values } = useQuery({
    queryKey: ['OPEN_SEA_ADDRESS', address],
    queryFn: async () => {
      if (!address) {
        return {
          data: [],
        };
      }

      // return mockData;
      if (blockchainNetwork === 'hardhat') {
        // return { data: null }
        return mockData;
      }

      const res = await axios.request({
        baseURL: openSeaBaseUrl,
        url: `/assets?limit=50&owner=${address}`,
        headers: { 'x-api-key': openSeaApiKey },
      });

      return res.data;
    },
    retry: false,
    staleTime: 60 * 1000,
  });

  return {
    data,
    ...values,
  };
}

export default useOpenSeaAddressQuery;
