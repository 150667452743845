import MetaMaskImg from 'images/metamask.png';
// import PolygonImg from 'images/polygon.png';
import EthereumImg from 'images/ethereum.png';

function Supported() {
  return (
    <section className="flex flex-col justify-center items-center bg-[#000] w-full pt-[50px] pb-[100px] px-[100px]">
      <h2 className="text-white font-black text-[32px]">
        Supported <span className="text-[#FCF952]">Wallets & Chains</span>
      </h2>
      <div className="bg-white w-[950px] flex items-center flex-col py-[30px] mt-[20px]">
        <div className="flex">
          <img src={EthereumImg} alt="Ethereum chain" className="w-[200px]" />
          <img src={MetaMaskImg} alt="MetaMask wallet" className="w-[200px]" />
        </div>
      </div>
    </section>
  );
}

export default Supported;
