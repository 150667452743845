import { message } from 'antd';
import { useContext } from 'react';
import { ethers, parseEther } from 'ethers';
import { chooseContractAddress, chooseAbi } from 'smart-contracts';

import ContractsContext from 'contexts/contracts';

const MINT_AMOUNT = '1000';

function Mint({ userAddress }: { userAddress: string }) {
  const { provider } = useContext(ContractsContext);

  async function onMintClicked() {
    const signer = await provider.getSigner();
    const lithTokenContractWrite = new ethers.Contract(
      chooseContractAddress('LITH_TOKEN_CONTRACT'),
      chooseAbi('ERC20_MINT'),
      signer
    );

    const value = parseEther(MINT_AMOUNT).toString();

    const transaction = await lithTokenContractWrite.mint(userAddress, value);
    message.loading({
      key: 'mint-token',
      content: 'Minting test $LITH tokens...',
      duration: 0,
    });

    const receipt = await transaction.wait();

    message.destroy('mint-token');
    message.success({
      content: (
        <div>Test $LITH tokens minted, see transaction {`${receipt.hash}`}</div>
      ),
    });
  }

  return (
    <button className="font-bold mr-[10px]" onClick={onMintClicked}>
      <span className="bg-[#FCF952] px-[20px] py-[10px]">Mint test $LITH</span>
    </button>
  );
}

export default Mint;
