import type { BrowserProvider as ProviderI } from 'ethers/types/providers/provider-browser.d.ts';
import {
  chooseAbi,
  chooseContractAddress,
  chooseInfuraProvider,
  chooseProvider,
} from 'smart-contracts';
import { createContext, useMemo, ReactNode } from 'react';
import { ethers } from 'ethers';

interface ContextI {
  provider: ProviderI;
  infuraProvider: any;
  escrowContractRead: any;
  lithTokenContractRead: any;
  wethTokenContractRead: any;
  usdtTokenContractRead: any;
}

const ContractsContext = createContext<ContextI>({} as ContextI);

export function ContractsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const value = useMemo(() => {
    const infuraProvider = chooseInfuraProvider();
    const provider = chooseProvider();

    // read only use infura
    const escrowContractRead = new ethers.Contract(
      chooseContractAddress('ESCROW_CONTRACT'),
      chooseAbi('ESCROW_CONTRACT'),
      infuraProvider
    );
    const lithTokenContractRead = new ethers.Contract(
      chooseContractAddress('LITH_TOKEN_CONTRACT'),
      chooseAbi('ERC20'),
      infuraProvider
    );
    const wethTokenContractRead = new ethers.Contract(
      chooseContractAddress('WETH_TOKEN_CONTRACT'),
      chooseAbi('ERC20'),
      infuraProvider
    );
    const usdtTokenContractRead = new ethers.Contract(
      chooseContractAddress('USDT_TOKEN_CONTRACT'),
      chooseAbi('ERC20'),
      infuraProvider
    );

    return {
      provider,
      infuraProvider,
      escrowContractRead,
      lithTokenContractRead,
      wethTokenContractRead,
      usdtTokenContractRead,
    };
  }, []);

  return (
    <ContractsContext.Provider value={value}>
      {children}
    </ContractsContext.Provider>
  );
}

export default ContractsContext;
