import { login } from 'libs/auth';
import { PlusOutlined } from '@ant-design/icons';

function AssetPreviewBlank({
  isFirst,
  setMode,
  isDisabled,
  isLoggedIn,
}: {
  isFirst: boolean;
  setMode: Function;
  isDisabled: boolean;
  isLoggedIn: boolean;
}) {
  async function onClick() {
    await login();
  }

  return (
    <div
      className={`w-[140px] h-[140px] bg-white flex justify-center items-center ${
        !isDisabled && 'cursor-pointer'
      } ${!isFirst && 'bg-opacity-60'} ${
        isDisabled && 'hover:cursor-not-allowed'
      }`}
      onClick={() => {
        if (!isLoggedIn) {
          return onClick();
        }
        return !isDisabled && setMode('DEFAULT');
      }}
    >
      <div className="bg-[#B8B502] w-[13px] h-[13px] flex justify-center items-center text-white font-bold">
        <PlusOutlined className="text-[13px]" />
      </div>
    </div>
  );
}

export default AssetPreviewBlank;
