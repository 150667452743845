import _ from 'lodash';
import { Select, Space, Input, Divider } from 'antd';
import { IAsset, IToken } from 'components/Escrow';
import { SUPPORTED_TOKEN_ADDRESSES } from 'smart-contracts/constants';
import { CloseOutlined } from '@ant-design/icons';
import { formatEther } from 'ethers';
import { chooseContractAddress } from 'smart-contracts';

// components
import AssetPreviewBlank from 'components/AssetPreviewBlank';

// queries
import useLithBalanceQuery from 'hooks/use-lith-balance-query';
import useWethBalanceQuery from 'hooks/use-weth-balance-query';
import useUsdtBalanceQuery from 'hooks/use-usdt-balance-query';

function AssetPreview({
  isLoggedIn,
  address,
  assets,
  selectedAssets,
  setFn,
  onNFTClicked,
  selectedToken,
  setSelectedToken,
  mode,
  setMode,
  isDisabled = false,
}: {
  isLoggedIn: boolean;
  address: string | null;
  assets: any[];
  selectedAssets: IAsset[];
  setFn: Function;
  onNFTClicked: Function;
  selectedToken: IToken | null;
  setSelectedToken: ({ address, amount }: IToken) => void;
  mode: string;
  setMode: Function;
  isDisabled?: boolean;
}) {
  const { data: lithBalance } = useLithBalanceQuery({ address });
  const { data: wethBalance } = useWethBalanceQuery({ address });
  const { data: usdtBalance } = useUsdtBalanceQuery({ address });

  function onTokenSearchChange(address: string) {
    setSelectedToken({ ...selectedToken, address });
  }

  function renderYourAssets() {
    return _.fill(Array(5), null).map((_, i) => {
      if (selectedAssets[i]) {
        const { tokenId, address, imgUrl, collectionName } = selectedAssets[i];
        const isSelected = selectedAssets.find((a) => {
          return a.tokenId === tokenId && a.address === address;
        });

        return (
          <li
            className={`relative w-[140px] h-[140px] cursor-pointer`}
            key={tokenId}
            onClick={() => {
              return setMode('SELECT_YOUR_ASSETS');
            }}
          >
            {isSelected && (
              <div className="absolute right-[10px] top-[8px] bg-[#C3C002] w-[20px] h-[20px] flex justify-center items-center">
                <CloseOutlined className="text-white text-[12px]" />
              </div>
            )}
            <img
              alt={`${collectionName} #${tokenId}`}
              src={imgUrl}
              className="h-[100px] w-[140px]"
            />
            <div className="bg-white p-[5px] h-[55px] flex flex-col items-center justify-center">
              <div className="text-[14px] truncate text-black max-w-[120px]">{`#${tokenId}`}</div>
              <div className="text-[12px] text-[#B8B500] truncate w-full text-center">
                {collectionName}
              </div>
            </div>
          </li>
        );
      }

      return (
        <AssetPreviewBlank
          key={i}
          isFirst={i === 0}
          setMode={setMode}
          isDisabled={isDisabled}
          isLoggedIn={isLoggedIn}
        />
      );
    });
  }

  function renderBalance() {
    const blank = ' ---';
    if (selectedToken && selectedToken.address) {
      switch (selectedToken.address) {
        case chooseContractAddress('LITH_TOKEN_CONTRACT'):
          return lithBalance === undefined
            ? blank
            : `${formatEther(lithBalance).toString()} LITH`;
        case chooseContractAddress('WETH_TOKEN_CONTRACT'):
          return wethBalance === undefined
            ? blank
            : `${formatEther(wethBalance).toString()} WETH`;
        case chooseContractAddress('USDT_TOKEN_CONTRACT'):
          return usdtBalance === undefined
            ? blank
            : `${formatEther(usdtBalance).toString()} USDT`;
        default:
          return blank;
      }
    }

    return blank;
  }

  return (
    <Space direction="vertical">
      <div className="bg-[#FCF951] w-[800px] p-[20px]">
        <div className="text-[#010211] text-lg font-bold">
          {`Selected ${selectedAssets.length} / 5 NFT(s)`}
        </div>
        <ul className="flex justify-between mt-[10px]">
          {mode === 'DEFAULT' && renderYourAssets()}
        </ul>
        <Divider className="bg-[#B8B500]" />
        <div className="flex justify-between w-[450px] items-center">
          <div className="text-[#010211] text-lg font-bold">
            Select ERC20 token
          </div>
          <div className="text-[14px] font-bold text-black">
            <span>Balance: </span>
            {renderBalance()}
          </div>
        </div>
        <Space className="mt-[20px]">
          <div className="bg-white w-[450px] p-[15px] flex justify-between">
            <Input
              className="bg-white w-[200px]"
              bordered={false}
              placeholder="Amount"
              onChange={(e) =>
                setSelectedToken({
                  ...selectedToken,
                  amount: e.target.value || '',
                })
              }
              type="number"
              disabled={isDisabled}
            />
            <Select
              onChange={onTokenSearchChange}
              style={{
                width: '200px',
              }}
              dropdownStyle={{
                backgroundColor: '#FFF',
              }}
              placeholder="Select token..."
              options={[
                {
                  label: 'ERC 20 Tokens',
                  options: SUPPORTED_TOKEN_ADDRESSES.map((t) => ({
                    ...t,
                    key: t.label,
                  })),
                },
              ]}
              disabled={isDisabled}
            />
          </div>
        </Space>
      </div>
    </Space>
  );
}

export default AssetPreview;
