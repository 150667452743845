import { ConfigProvider } from 'antd';
import Bugsnag from 'libs/bugsnag';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import React from 'react';

import './App.css';

import { ContractsContextProvider } from 'contexts/contracts';
import { WindowSizeContextProvider } from 'contexts/window-size';

// components
import Error from 'components/Error';
import Home from 'components/Home';

const ErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(React);
const queryClient = new QueryClient();

function App() {
  const children = (
    <QueryClientProvider client={queryClient}>
      <WindowSizeContextProvider>
        <ContractsContextProvider>
          <ConfigProvider
            theme={{
              token: {
                colorBgBase: '#FCF952',
              },
            }}
          >
            <Home />
          </ConfigProvider>
          <ReactQueryDevtools />
        </ContractsContextProvider>
      </WindowSizeContextProvider>
    </QueryClientProvider>
  );

  return ErrorBoundary ? (
    <header className="App-header">
      <ErrorBoundary FallbackComponent={Error}>{children}</ErrorBoundary>
    </header>
  ) : (
    <header className="App-header">{children}</header>
  );
}

export default App;
