import { Button, Typography, Space } from 'antd';

const { Title, Paragraph } = Typography;

function onClick() {
  window.location.replace('/');
}

function ErrorView() {
  return (
    <section className="">
      <Space direction="vertical" className="items-center">
        <Title level={1}>Sorry, something went wrong :/</Title>
        <Paragraph>Please refresh the page or contact support</Paragraph>
        <Button onClick={onClick}>Back to Home</Button>
      </Space>
    </section>
  );
}

export default ErrorView;
