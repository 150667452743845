import { Tooltip } from 'antd';
import { CopyOutlined, TwitterOutlined } from '@ant-design/icons';
import { Layout, message } from 'antd';
import React, { useState, useEffect } from 'react';
import PeasySwapImg from 'images/peasy-swap-img.png';
import EthereumIconImg from 'images/eth-icons/eth-dark.svg';
import EtherscanImg from 'images/etherscan-icons/etherscan-logo-circle-light.svg';
import TelegramIconImg from 'images/telegram-icons/telegram-icon.svg';
import { login } from 'libs/auth';
import { wait, shortenString } from 'utils';
import envVars from 'config';
import mixpanel from 'libs/mixpanel';

// components
import Mint from 'components/Mint';
import Intro from 'components/Intro';
import HowItWorks from 'components/HowItWorks';
import Supported from 'components/Supported';
import Main from 'components/Main';

const { Header, Content, Footer } = Layout;

const {
  main: { blockchainNetwork },
} = envVars;

const hardhatNetwork = 'Hardhat Network';
const goerliNetwork = 'Goerli Testnet';
const mainnetNetwork = 'Mainnet';

interface nativeCurrencyI {
  name: string;
  symbol: string;
  decimals: number;
}

function Home() {
  const [isOnCorrectNetwork, setIsOnCorrectNetwork] = useState(
    typeof window !== 'undefined' &&
      window.ethereum &&
      ((blockchainNetwork === 'mainnet' &&
        window.ethereum?.networkVersion === '1') ||
        (blockchainNetwork === 'goerli' &&
          window.ethereum?.networkVersion === '5') ||
        (blockchainNetwork === 'hardhat' &&
          window.ethereum?.networkVersion === '31337'))
  );
  const [metaMaskAddress, setMetaMaskAddress] = useState<string | null>(null);
  const [swapCode, setSwapCode] = useState<string | null>(null);

  useEffect(() => {
    window &&
      window.ethereum &&
      window.ethereum
        .request({ method: 'eth_accounts' })
        .then(([address]: [string]) => {
          mixpanel.identify(address);
          mixpanel.people.set({
            $publicAddress: address,
          });
          mixpanel.track('USER_SIGNED_IN');
          return setMetaMaskAddress(address);
        });
  }, [metaMaskAddress]);

  async function connect() {
    const address = await login();
    setMetaMaskAddress(address);
  }

  let chainId: string;
  let rpcUrls: string[];
  let networkVersion: string;
  let chainName: string;
  let nativeCurrency: nativeCurrencyI;
  let blockExplorerUrls: string[];

  switch (blockchainNetwork) {
    case 'mainnet':
      chainId = '0x1';
      networkVersion = '1';
      rpcUrls = [
        'https://eth.llamarpc.com',
        'https://ethereum.publicnode.com',
        'https://eth-rpc.gateway.pokt.network',
      ];
      chainName = 'Ethereum Mainnet';
      nativeCurrency = {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      };
      blockExplorerUrls = ['https://etherscan.io'];
      break;
    case 'goerli':
      chainId = '0x5';
      networkVersion = '5';
      rpcUrls = [
        'https://ethereum-goerli.publicnode.com',
        'https://goerli.blockpi.network/v1/rpc/public',
        'https://eth-goerli.public.blastapi.io',
      ];
      chainName = 'Ethereum Goerli';
      nativeCurrency = {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      };
      blockExplorerUrls = ['https://goerli.etherscan.io/'];
      break;
    default: // hardhat
      chainId = '0x7a69';
      networkVersion = '31337';
      rpcUrls = ['http://localhost:8545'];
      chainName = 'Localhost 8545';
      nativeCurrency = {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      };
      blockExplorerUrls = ['https://goerli.etherscan.io/'];
  }

  async function onSwitchNetworkClicked() {
    if (
      typeof window !== 'undefined' &&
      window.ethereum &&
      !isOnCorrectNetwork
    ) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId,
              rpcUrls,
              chainName,
              nativeCurrency,
              blockExplorerUrls,
            },
          ],
        });

        await wait(1000);

        if (
          window.ethereum?.networkVersion === networkVersion &&
          blockchainNetwork === 'goerli'
        ) {
          setIsOnCorrectNetwork(true);
          message.success(
            `Network successfully switched to ${goerliNetwork}`,
            1
          );
          connect();
        }
        if (
          window.ethereum?.networkVersion === networkVersion &&
          blockchainNetwork === 'mainnet'
        ) {
          setIsOnCorrectNetwork(true);
          message.success(
            `Network successfully switched to ${mainnetNetwork}`,
            1
          );
          connect();
        }
        if (
          window.ethereum?.networkVersion === networkVersion &&
          blockchainNetwork === 'hardhat'
        ) {
          setIsOnCorrectNetwork(true);
          message.success(
            `Network successfully switched to ${hardhatNetwork}`,
            1
          );
          connect();
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  function metamaskAction() {
    if (
      typeof window !== 'undefined' &&
      typeof window.ethereum === 'undefined'
    ) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://metamask.io/download/"
        >
          <span className="bg-[#FCF952] px-[20px] py-[10px] font-bold">
            Download MetaMask
          </span>
        </a>
      );
    }

    if (metaMaskAddress) {
      return (
        <div className="flex items-center">
          {blockchainNetwork !== 'mainnet' && (
            <Mint userAddress={metaMaskAddress} />
          )}
          <Tooltip placement="bottom" title={metaMaskAddress}>
            <div className="font-bold">
              <span className="bg-[#FCF952] px-[20px] py-[10px]">
                Connected {shortenString(metaMaskAddress)}
              </span>
            </div>
          </Tooltip>
        </div>
      );
    }

    if (!isOnCorrectNetwork) {
      return (
        <button
          className="font-bold mr-[10px]"
          onClick={onSwitchNetworkClicked}
        >
          <span className="bg-[#FCF952] px-[20px] py-[10px]">
            Connect Wallet
          </span>
        </button>
      );
    }

    return (
      <button
        className="font-bold mr-[10px]"
        onClick={!isOnCorrectNetwork ? onSwitchNetworkClicked : connect}
      >
        <span className="text-black bg-[#FCF952] px-[20px] py-[10px]">
          Connect Wallet
        </span>
      </button>
    );
  }

  function onCopyClicked() {
    if (swapCode) {
      navigator.clipboard.writeText(swapCode);
      message.success('Successfully copied to clipboard!');
    }
  }

  return (
    <Layout className="w-full bg-[#1E1E1E]">
      <Header className="flex justify-between bg-transparent mt-[20px] items-center">
        <img src={PeasySwapImg} alt="logo" className="w-[250px]" />
        <div className="flex items-center">
          <div className="text-white mr-[10px]">Supporting chains:</div>
          <div className="bg-white mr-[10px] p-[4px]">
            <img src={EthereumIconImg} alt="logo" className="w-[25px]" />
          </div>
          <div>{metamaskAction()}</div>
        </div>
      </Header>
      <Content className="flex justify-center flex-col items-center">
        <Intro />
        <div id="swap-code" />
        {swapCode && (
          <div className="bg-[#FCF952] w-[950px] p-[50px]">
            <div className="font-bold text-black text-[24px] mb-[10px]">
              Copy the SWAP code
            </div>
            <p className="text-black text-[18px] mb-[10px]">
              Send the Swap Code to your counterpart to complete the swap.
            </p>
            <div className="bg-[#D9D9D9] font-bold text-black mb-[10px] p-[20px] break-all">
              {swapCode}
            </div>
            <div className="flex items-center my-[20px]">
              <button
                className="bg-black font-bold text-[#FCF952] text-[18px] px-[40px] py-[10px]"
                onClick={onCopyClicked}
              >
                Copy
                <CopyOutlined className="ml-[5px]" />
              </button>
            </div>
            <p className="text-[#010211] text-[18px]">
              Please ensure both parties to have the assets ready in the wallet
              when executing the swap.
            </p>
          </div>
        )}
        <Main
          metaMaskAddress={metaMaskAddress}
          swapCode={swapCode}
          setSwapCode={setSwapCode}
        />
        <HowItWorks />
        <Supported />
      </Content>
      <Footer className="flex justify-between items-center bg-transparent text-[#FCF952] font-bold py-[20px]">
        <div className="flex items-center">
          <a
            href="https://t.me/PEASYlabs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TelegramIconImg}
              alt="telegram"
              className="w-[20px] mr-[10px]"
            />
          </a>
          <a
            href="https://twitter.com/PEASYlabs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterOutlined className="text-[22px] text-[#1DA1F2] mr-[5px] mt-[3px]" />
          </a>
          <a
            href="https://etherscan.io/address/0x0dd32CcF0C26b4Edcd3472650c493aCC87859878#code"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-[5px]"
          >
            <img src={EtherscanImg} alt="etherscan" className="w-[20px]" />
          </a>
        </div>
        <div>Peasy Swap ©2023</div>
      </Footer>
    </Layout>
  );
}

export default Home;
