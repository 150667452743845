import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import envVars from 'config';

const {
  services: { bugSnagApiKey },
  main: { appEnvironment },
} = envVars;

Bugsnag.start({
  apiKey: bugSnagApiKey,
  plugins: [new BugsnagPluginReact()],
  releaseStage: appEnvironment,
  enabledReleaseStages: ['production', 'staging'],
  // appVersion: publicRuntimeConfig?.herokuReleaseVersion,
});

export default Bugsnag;
