// import type { FormatType, Interface } from 'ethers/types/abi/index.d.ts';
// import type { BrowserProvider } from 'ethers/types/providers/provider-browser.d.ts';

import { abiLookup, allAbiLookup } from 'smart-contracts/abis';
import { ethers, InfuraProvider } from 'ethers';
import envVars, {
  escrowContractAddressLookup,
  wethTokenAddressLookup,
  lithTokenAddressLookup,
  usdtTokenAddressLookup,
} from 'config';

const {
  main: { blockchainNetwork },
  services: { infuraId },
} = envVars;

const explorerLookup = {
  mainnet: 'https://etherscan.io/',
  goerli: 'https://goerli.etherscan.io/',
  hardhat: 'https://goerli.etherscan.io/',
};

const addressLookup: any = {
  ESCROW_CONTRACT: escrowContractAddressLookup,
  WETH_TOKEN_CONTRACT: wethTokenAddressLookup,
  LITH_TOKEN_CONTRACT: lithTokenAddressLookup,
  USDT_TOKEN_CONTRACT: usdtTokenAddressLookup,
};

export const ETHEREUM_NETWORK = {
  mainnet: 'mainnet',
  goerli: 'goerli',
  hardhat: 'http://localhost:8545',
};

export function chooseNetwork() {
  return ETHEREUM_NETWORK[process.env.REACT_APP_BLOCKCHAIN_NETWORK];
}

export function chooseExplorer() {
  return explorerLookup[process.env.REACT_APP_BLOCKCHAIN_NETWORK];
}

export function chooseInfuraProvider() {
  return blockchainNetwork === 'hardhat'
    ? new ethers.BrowserProvider(window.ethereum)
    : new InfuraProvider(chooseNetwork(), infuraId);
}

export function chooseProvider() {
  return blockchainNetwork === 'hardhat'
    ? new ethers.BrowserProvider(window.ethereum)
    : new ethers.BrowserProvider(window.ethereum, chooseNetwork());
}

export function chooseAbi(type: string): any {
  return abiLookup[type];
}

export function chooseAbiByAddress(address: string) {
  return allAbiLookup[address];
}

export function chooseContractAddress(type: string) {
  return addressLookup[type][process.env.REACT_APP_BLOCKCHAIN_NETWORK];
}
