export function wait(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Shorten a string
 * @param {string} address
 * @param {number} [headLength=5]
 * @param {number} [tailLength=5]
 * @returns {string} shortened address
 */
export function shortenString(str: string, headLength = 5, tailLength = 5) {
  return `${str.substring(0, headLength)}...${str.substring(
    str.length - tailLength
  )}`;
}
