import { SwapOutlined, ArrowDownOutlined } from '@ant-design/icons';

function Item({
  text,
  withDownArrow,
}: {
  text: string;
  withDownArrow: boolean;
}) {
  return (
    <div className="my-[15px] w-[450px] h-[70px] border-4 border-[#FCF952] py-[10px] flex justify-center items-center text-[#FCF952] text-[18px] relative">
      {text}
      {withDownArrow && (
        <ArrowDownOutlined className="absolute bottom-[-34px] text-[30px]" />
      )}
    </div>
  );
}

function HowItWorks() {
  return (
    <section className="flex flex-col justify-center items-center bg-[#7C7A0030] w-full py-[50px] px-[100px]">
      <h2 className="text-white font-black text-[32px]">
        How it <span className="text-[#FCF952]">Works</span>?
      </h2>
      <p className="text-[18px] mt-[10px]">
        You can SWAP up to 5 NFTs from any collections with any ERC20 token with
        your counterpart.
      </p>
      <div className="flex mt-[30px]">
        <div className="flex flex-col items-center">
          <div className="text-[#FCF952] text-[24px] font-bold mb-[10px]">
            Initiate
            <span className="ml-[5px] text-white">a SWAP</span>
          </div>
          <Item
            withDownArrow
            text="Initiate a SWAP by filling out the details"
          />
          <Item withDownArrow text="Confirm and get the SWAP code" />
          <Item
            withDownArrow={false}
            text="Send the SWAP code to your counterpart"
          />
        </div>
        <div className="mx-[20px]">
          <SwapOutlined className="text-[32px]" />
        </div>
        <div>
          <div className="flex flex-col items-center">
            <div className="text-[#FCF952] text-[24px] font-bold mb-[10px]">
              Complete
              <span className="ml-[5px] text-white">a SWAP</span>
            </div>
            <Item withDownArrow text="Paste the SWAP code into the field" />
            <Item withDownArrow text="Review the details" />
            <Item
              withDownArrow={false}
              text="Complete the SWAP before it expires!"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
