import envVars from 'config';
import mixpanel from 'mixpanel-browser';

const {
  services: { mixpanelProjectToken },
  main: { appEnvironment },
} = envVars;

mixpanel.init(mixpanelProjectToken, {
  debug: appEnvironment === 'development',
});

export default mixpanel;
