import { useState } from 'react';

// components
import Escrow from 'components/Escrow';
import Execute from 'components/Execute';

function Main({
  metaMaskAddress,
  setSwapCode,
  swapCode,
}: {
  metaMaskAddress: null | string;
  setSwapCode: Function;
  swapCode: null | string;
}) {
  const [tab, setTab] = useState<'CREATE' | 'COMPLETE'>('CREATE');

  const activeAttrs =
    'bg-[#FCF952] text-black flex justify-center w-[50%] font-bold text-[30px] py-[10px] cursor-pointer';
  const inactiveAttrs =
    'border-4 border-[#FCF952] text-white flex justify-center w-[50%] font-bold text-[30px] py-[6px] cursor-pointer';

  return (
    <div className="flex my-[50px] flex-col justify-center">
      <div className="flex justify-center">
        <div className="font-black text-white text-[45px] mr-[10px]">Get</div>
        <div className="font-black text-[#FCF951] text-[45px]">Started</div>
      </div>
      <div className="flex flex-col w-[950px] mt-[20px]">
        <div className="flex">
          <div
            className={tab === 'CREATE' ? activeAttrs : inactiveAttrs}
            onClick={() => setTab('CREATE')}
          >
            Create a SWAP
          </div>
          <div
            className={tab === 'COMPLETE' ? activeAttrs : inactiveAttrs}
            onClick={() => setTab('COMPLETE')}
          >
            Complete a SWAP
          </div>
        </div>
        <div className="border-l-[#FCF952] border-b-[#FCF952] border-r-[#FCF952] border-l-4 border-b-4 border-r-4">
          <div className={`${tab !== 'CREATE' ? 'hidden' : ''}`}>
            <Escrow setSwapCode={setSwapCode} swapCode={swapCode} />
          </div>
          <div className={`${tab !== 'COMPLETE' ? 'hidden' : ''}`}>
            <Execute />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
