import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import ContractsContext from 'contexts/contracts';

function useUsdtBalanceQuery({ address }: { address: string | null }) {
  const { usdtTokenContractRead } = useContext(ContractsContext);

  const { data, ...values } = useQuery(
    ['USDT_BALANCE', address],
    () => usdtTokenContractRead.balanceOf(address),
    {
      enabled: Boolean(typeof window !== 'undefined' && window.ethereum),
      staleTime: 5 * 1000,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    ...values,
  };
}

export default useUsdtBalanceQuery;
