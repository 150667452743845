import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import Bugsnag from 'libs/bugsnag';
import { dayjs } from 'libs/dayjs';
import _ from 'lodash';
import { Base64 } from 'js-base64';
import { ethers } from 'ethers';
import { useContext, useState } from 'react';
import { Space, message } from 'antd';
import { TOKEN_TYPE } from 'smart-contracts/constants';
import {
  chooseExplorer,
  chooseContractAddress,
  chooseAbi,
} from 'smart-contracts';
import mixpanel from 'libs/mixpanel';

import ContractsContext from 'contexts/contracts';
import useSignerAddressQuery from 'hooks/use-signer-address-query';

// components
import AssetPreview from 'components/execute/AssetPreview';

export interface IData {
  imgUrl: string;
  name: string;
  tokenId: string;
  type: 0 | 1 | 2; // see constants
  address: string;
  amount: string;
}

function Execute() {
  const { provider } = useContext(ContractsContext);

  const [swapCode, setSwapCode] = useState<string>('');
  const [executorData, setExecutorData] = useState<IData[] | null>(null);
  const [signerData, setSignerData] = useState<IData[] | null>(null);
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const [executorAddress, setExecutorAddress] = useState<string | null>(null);
  const [signerAddress, setSignerAddress] = useState<string | null>(null);

  const [expiration, setExpiration] = useState<number>(0);
  const [swapSignature, setSwapSignature] = useState<string | null>(null);

  const [isSwapComplete, setIsSwapComplete] = useState<boolean>(false);
  const [swapTxHref, setSwapTxHref] = useState<string>('');

  const { data: address } = useSignerAddressQuery();

  async function onExecuteClicked() {
    const decoded = JSON.parse(Base64.decode(swapCode));

    const {
      unencodedData: {
        signerSide: {
          trader: signerAddress,
          tokenType: signerTokenTypes,
          tokenAddress: signerTokenAddresses,
          tokenId: signerTokenIds,
          tokenAmount: signerTokenAmounts,
        },
        executorSide: {
          trader: executorAddress,
          tokenType: executorTokenTypes,
          tokenAddress: executorTokenAddresses,
          tokenId: executorTokenIds,
          tokenAmount: executorTokenAmounts,
        },
      },
      nonce,
      expiryTimestamp,
      signature,
    } = decoded;

    const signer = await provider.getSigner();

    for (let i = 0; i < executorTokenTypes.length; i++) {
      const assetTokenType = executorTokenTypes[i];
      const assetAddress = executorTokenAddresses[i];
      const assetAmount = executorTokenAmounts[i];

      const tokenTypeLookup = _.invert(TOKEN_TYPE);

      if (tokenTypeLookup[assetTokenType] === 'ERC721') {
        const contract = new ethers.Contract(
          assetAddress,
          chooseAbi('ERC721'),
          signer
        );

        const isApproved = await contract.isApprovedForAll(
          signer.address,
          chooseContractAddress('ESCROW_CONTRACT')
        );

        if (!isApproved) {
          const approvalTx = await contract.setApprovalForAll(
            chooseContractAddress('ESCROW_CONTRACT'),
            true
          );
          message.loading({
            key: '721-approval-pending',
            content: 'Approval pending on chain...',
            duration: 0,
          });

          const receipt = await approvalTx.wait();

          message.destroy('721-approval-pending');
          message.success({
            duration: 5,
            content: (
              <div>
                Approval successful, see transaction {`${receipt.hash}`}
              </div>
            ),
          });
        }
      }

      if (tokenTypeLookup[assetTokenType] === 'ERC1155') {
        const contract = new ethers.Contract(
          assetAddress,
          chooseAbi('ERC1155'),
          signer
        );

        const isApproved = await contract.isApprovedForAll(
          signer.address,
          chooseContractAddress('ESCROW_CONTRACT')
        );

        if (!isApproved) {
          const approvalTx = await contract.setApprovalForAll(
            chooseContractAddress('ESCROW_CONTRACT'),
            true
          );

          message.loading({
            key: '1155-approval-pending',
            content: 'Approval pending on chain...',
            duration: 0,
          });

          const receipt = await approvalTx.wait();

          message.destroy('1155-approval-pending');
          message.success({
            duration: 5,
            content: (
              <div>
                Approval successful, see transaction {`${receipt.hash}`}
              </div>
            ),
          });
        }
      }

      if (tokenTypeLookup[assetTokenType] === 'ERC20') {
        const contract = new ethers.Contract(
          assetAddress,
          chooseAbi('ERC20'),
          signer
        );

        const allowance = await contract.allowance(
          signer.address,
          chooseContractAddress('ESCROW_CONTRACT')
        );

        if (BigInt(assetAmount) > allowance) {
          const approvalTx = await contract.approve(
            chooseContractAddress('ESCROW_CONTRACT'),
            ethers.MaxUint256
          );

          message.loading({
            key: '20-approval-pending',
            content: 'Approval pending on chain...',
            duration: 0,
          });

          const receipt = await approvalTx.wait();

          message.destroy('20-approval-pending');
          message.success({
            duration: 5,
            content: (
              <div>
                Approval successful, see transaction {`${receipt.hash}`}
              </div>
            ),
          });
        }
      }
    }

    const escrowContractWrite = new ethers.Contract(
      chooseContractAddress('ESCROW_CONTRACT'),
      chooseAbi('ESCROW_CONTRACT'),
      signer
    );

    const payload = [
      nonce,
      [
        signerAddress,
        signerTokenTypes,
        signerTokenAddresses,
        signerTokenIds,
        signerTokenAmounts,
      ],
      [
        executorAddress,
        executorTokenTypes,
        executorTokenAddresses,
        executorTokenIds,
        executorTokenAmounts,
      ],
      expiryTimestamp,
    ];
    console.log({
      payload,
    });

    const isSignatureValid = await escrowContractWrite.verifySignature(
      payload,
      signature
    );

    console.log({ isSignatureValid });

    const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } =
      await provider.getFeeData();
    console.log({
      gasPrice,
      maxFeePerGas,
      maxPriorityFeePerGas,
    });

    let transaction;
    try {
      transaction = await escrowContractWrite.swap(
        payload,
        signature
        // {
        //   gasLimit: 40000000,
        //   gasPrice,
        // }
      );
    } catch (err: any) {
      console.error(err);
      Bugsnag.notify(err);
      return message.error('Sorry something went wrong...');
    }

    message.loading({
      key: 'swap-executed',
      content: 'Swap pending on chain...',
      duration: 0,
    });

    const receipt = await transaction.wait();

    message.destroy('swap-executed');
    message.success({
      duration: 30,
      content: (
        <div>
          Swap successfully executed, see transaction {`${receipt.hash}`}
        </div>
      ),
    });

    setSwapTxHref(`${chooseExplorer()}tx/${receipt.hash}`);
    mixpanel.track('USER_EXECUTED_SWAP');
    setIsSwapComplete(true);
  }

  async function onValidateClicked() {
    let decoded;

    try {
      decoded = JSON.parse(Base64.decode(swapCode));
    } catch (err) {
      return message.error('Sorry the SwapCode is malformed');
    }

    const {
      unencodedData: {
        signerSide: {
          trader: signerAddress,
          tokenType: signerTokenTypes,
          tokenAddress: signerTokenAddresses,
          tokenId: signerTokenIds,
          tokenAmount: signerTokenAmounts,
          tokenImgUrl: signerTokenImgUrls,
          tokenCollectionName: signerTokenCollectionNames,
        },
        executorSide: {
          trader: executorAddress,
          tokenType: executorTokenTypes,
          tokenAddress: executorTokenAddresses,
          tokenId: executorTokenIds,
          tokenAmount: executorTokenAmounts,
          tokenImgUrl: executorTokenImgUrls,
          tokenCollectionName: executorTokenCollectionNames,
        },
      },
      nonce,
      expiryTimestamp,
      signature,
    } = decoded;

    if (_.toLower(address) === _.toLower(signerAddress)) {
      return message.error('You cannot complete a SWAP created by you');
    }

    const rawSignerData = signerTokenIds.map((tokenId: string, i: number) => {
      const imgUrl = signerTokenImgUrls[i];
      const collectionName = signerTokenCollectionNames[i];
      const type = signerTokenTypes[i];
      const address = signerTokenAddresses[i];
      const amount = signerTokenAmounts[i];

      return {
        imgUrl,
        name: `${collectionName} #${tokenId}`,
        tokenId,
        type,
        address,
        amount,
      };
    });

    const rawExecutorData = executorTokenIds.map(
      (tokenId: string, i: number) => {
        const imgUrl = executorTokenImgUrls[i];
        const collectionName = executorTokenCollectionNames[i];
        const type = executorTokenTypes[i];
        const address = executorTokenAddresses[i];
        const amount = executorTokenAmounts[i];

        return {
          imgUrl,
          name: `${collectionName} #${tokenId}`,
          tokenId,
          type,
          address,
          amount,
        };
      }
    );

    const payload = [
      nonce,
      [
        signerAddress,
        signerTokenTypes,
        signerTokenAddresses,
        signerTokenIds,
        signerTokenAmounts,
      ],
      [
        executorAddress,
        executorTokenTypes,
        executorTokenAddresses,
        executorTokenIds,
        executorTokenAmounts,
      ],
      expiryTimestamp,
    ];

    const escrowContractRead = new ethers.Contract(
      chooseContractAddress('ESCROW_CONTRACT'),
      chooseAbi('ESCROW_CONTRACT'),
      provider
    );
    const isSignatureValid = await escrowContractRead.verifySignature(
      payload,
      signature
    );

    console.log('Signature validity', isSignatureValid);

    if (!isSignatureValid) {
      return message.error('Sorry the SwapCode is invalid');
    }

    setExecutorAddress(executorAddress);
    setSignerAddress(signerAddress);
    setSignerData(rawSignerData);
    setExecutorData(rawExecutorData);
    setExpiration(expiryTimestamp);
    setSwapSignature(signature);
    setIsValidated(true);
  }

  function onCopyClicked() {
    if (swapTxHref) {
      navigator.clipboard.writeText(swapTxHref);
      message.success('Successfully copied to clipboard!');
    }
  }

  return (
    <Space
      direction="vertical"
      size={16}
      className="w-[942px] bg-black p-[50px]"
    >
      {isSwapComplete ? (
        <>
          <div className="text-white font-bold text-[45px] flex justify-center">
            SWAP Completed ✅
          </div>
          <div className="text-black w-full bg-[#FCF952] flex flex-col font-bold mt-[40px] py-[60px] px-[50px] text-[30px]">
            Refer to the transaction reference below
            <div className="bg-[#D9D9D9] p-[20px] mt-[20px] text-[18px]">
              <a
                href={swapTxHref}
                target="_blank"
                rel="noopener noreferrer"
                className="break-all"
              >
                {swapTxHref}
                <LinkOutlined className="ml-[5px]" />
              </a>
            </div>
            <div className="flex items-center my-[20px]">
              <button
                className="bg-black font-bold text-[#FCF952] text-[18px] px-[40px] py-[10px]"
                onClick={onCopyClicked}
              >
                Copy
                <CopyOutlined className="ml-[5px]" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="uppercase text-3xl font-bold text-[#FCF951]">
            Paste the swap code
          </div>
          <div className="text-white text-lg">
            {isValidated ? (
              <>
                <p>SWAP code is valid ✅</p>
                <p>
                  {' '}
                  Verify the information below and click "Copmlete SWAP" to
                  proceed.
                </p>
              </>
            ) : (
              'Paste the SWAP code and click below to validate.'
            )}
          </div>
          {isValidated ? (
            <div className="mt-[20px]">
              <div className="bg-[#FCF952] break-all text-black font-bold p-[10px]">
                {swapCode}
              </div>
              {signerData && executorData && (
                <AssetPreview
                  signerAddress={signerAddress}
                  signerData={signerData}
                  executorAddress={executorAddress}
                  executorData={executorData}
                />
              )}
              <div className="uppercase text-3xl font-bold text-[#FCF951] mt-[50px]">
                Details
              </div>
              <div className="flex mt-[20px]">
                <div className="mr-[200px]">
                  <div className="text-[18px] text-white">
                    Transaction will expire on
                  </div>
                  <div className="text-[24px] font-bold text-[#FCF952]">
                    {dayjs(parseInt(`${expiration}000`, 10)).toISOString()}
                  </div>
                </div>
                <div>
                  <div className="text-[18px] text-white">Signature</div>
                  <div className="text-[24px] font-bold text-[#FCF952] break-all">
                    {swapSignature}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <textarea
              className="bg-transparent w-full h-[500px]"
              placeholder="Please enter your SWAP code here"
              onChange={(e) => setSwapCode(e.target.value)}
            />
          )}
          <div className="my-[60px] flex flex-col items-center justify-center">
            <button
              className="font-bold mr-[10px]"
              onClick={!isValidated ? onValidateClicked : onExecuteClicked}
            >
              <span className="text-black bg-[#FCF952] px-[60px] py-[10px]">
                {!isValidated ? 'Validate SWAP Code' : 'Complete SWAP'}
              </span>
            </button>
            {isValidated && (
              <p className="text-white text-[12px] mt-[40px]">
                You agree to the terms & conditions set out by PEASY Labs by
                clicking "Create a SWAP" or "Complete SWAP". Users are
                responsible for exercising caution and conducting due diligence
                before engaging in any transaction with their counterparts.
                PEASY Labs is not liable for any losses in the exchange of
                assets. Please be aware of scams and carefully check the asset's
                contract address before exchanging.
              </p>
            )}
          </div>
        </>
      )}
    </Space>
  );
}

export default Execute;
