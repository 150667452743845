import NoFeeImg from 'images/no-fee.png';
import NonCustodialImg from 'images/non-custodial.png';
import SafeImg from 'images/safe.png';
import GaslessImg from 'images/gasless.png';

function Item({ Img, alt, text }: { Img: string; alt: string; text: string }) {
  return (
    <div className="border-4 border-[#FCF952] flex flex-col justify-center w-[280px] items-center py-[20px] mt-[20px] mx-[10px]">
      <img src={Img} alt={alt} className="w-[50px]" />
      <div className="text-[20px] text-[#FCF952] font-bold mt-[10px]">
        {text}
      </div>
    </div>
  );
}

function Intro() {
  return (
    <section className="mt-[50px] mb-[100px] mx-[100px]">
      <div className="flex justify-center items-center">
        <div className="flex flex-col w-[50%] mx-[40px]">
          <h1 className="font-black text-white text-[64px]">
            <div>
              Easy <span className="text-[#FCF952]">P2P SWAP</span>
            </div>
          </h1>
          <p className="text-white text-[24px] mr-[80px] mt-[10px]">
            SWAP safely & securely with anyone on-chain in a fully decetralized
            way.
          </p>
        </div>
        <div className="flex flex-col w-[50%] mx-[40px]">
          <div className="flex">
            <Item Img={NoFeeImg} alt="No Fee" text="No Fee" />
            <Item
              Img={GaslessImg}
              alt="Gasless Agreement"
              text="Gasless Agreement"
            />
          </div>
          <div className="flex">
            <Item
              Img={NonCustodialImg}
              alt="non-custodial"
              text="Non-Custodial"
            />
            <Item
              Img={SafeImg}
              alt="100% Safe Atomic SWAP"
              text="100% Safe Atomic SWAP"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;
