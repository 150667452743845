import _ from 'lodash';
import { formatEther } from 'ethers';
import { Divider } from 'antd';
import { SUPPORTED_TOKEN_ADDRESSES } from 'smart-contracts/constants';

// components
import { IData } from 'components/Execute';

function Assets({
  data,
  text,
  address,
}: {
  data: IData[];
  text: string;
  address: string | null;
}) {
  const nfts = data.filter((a) => a.type === 1 || a.type === 2);
  const tokens = data.filter((a) => a.type === 0);

  return (
    <div className="bg-[#FCF952] p-[20px] mt-[20px]">
      <div className="w-full text-black font-bold text-[18px] flex flex-col">
        <div className="flex justify-between">
          <div className="">{text}</div>
          <div>{address}</div>
        </div>
        <Divider className="my-[20px] bg-[#B8B500] w-full" />
        <div>{nfts.length} Selected NFT(s)</div>
        <ul className="flex flex-wrap mx-[-12px]">
          {nfts.map((asset) => {
            const { imgUrl, tokenId, name } = asset;

            return (
              <li
                className={`relative w-[140px] h-[140px] cursor-pointer my-[10px] mx-[12px]`}
                key={tokenId}
              >
                <img
                  alt={`${name} #${tokenId}`}
                  src={imgUrl}
                  className="h-[100px] w-[140px]"
                />
                <div className="bg-white p-[5px] h-[55px] flex flex-col items-center justify-center">
                  <div className="font-bold text-[16px] truncate text-black">{`#${tokenId}`}</div>
                  <div className="text-[14px] text-[#B8B500] truncate w-full text-center">
                    {name}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <Divider className="my-[20px] bg-[#B8B500] w-full" />
        <div className="flex items-center">
          <div className="mr-[200px]">Seleted ERC20 Token</div>
          <ul className="flex flex-col">
            {_.isEmpty(tokens)
              ? 'N/A'
              : tokens.map((asset: IData) => {
                  const { address, amount } = asset;

                  return (
                    <li className="flex" key={address}>
                      <div className="mr-[5px]">{formatEther(amount)}</div>
                      {
                        SUPPORTED_TOKEN_ADDRESSES.find(
                          (a: { label: string; value: string }) =>
                            a.value === address
                        )?.label
                      }
                    </li>
                  );
                })}
          </ul>
        </div>
      </div>
    </div>
  );
}

function AssetPreview({
  signerAddress,
  signerData,
  executorAddress,
  executorData,
}: {
  signerAddress: string | null;
  signerData: IData[];
  executorAddress: string | null;
  executorData: IData[];
}) {
  return (
    <div className="mt-[100px]">
      <div className="flex flex-col">
        <div className="uppercase text-3xl font-bold text-[#FCF951]">
          You Pay
        </div>
        <Assets
          data={executorData}
          text="Your Address"
          address={executorAddress}
        />
      </div>

      <Divider className="mt-[50px] bg-[#FCF952] w-full" />
      <div className="flex flex-col mt-[50px]">
        <div className="uppercase text-3xl font-bold text-[#FCF951]">
          You Receive
        </div>
        <Assets
          data={signerData}
          text="Counterparty Address"
          address={signerAddress}
        />
      </div>
    </div>
  );
}

export default AssetPreview;
