import { useEffect } from 'react';
import { Tooltip } from 'antd';
import { IAsset, IToken } from 'components/Escrow';
import _ from 'lodash';
import {
  CheckCircleOutlined,
  WarningOutlined,
  CloseOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons';

function AssetSelection({
  assets, // all assets available for selection
  oldAssets, // original selection, for when reverting to when clicked "Back"
  selectedAssets, // selected assets
  setFn, // fn to set what's selected
  onNFTClicked,
  selectedToken,
  setSelectedToken,
  setMode,
}: {
  assets: any[];
  selectedAssets: IAsset[];
  oldAssets: IAsset[];
  setFn: Function;
  onNFTClicked: Function;
  selectedToken: IToken | null;
  setSelectedToken: ({ address, amount }: IToken) => void;
  setMode: Function;
}) {
  useEffect(() => {
    const section = document.querySelector('#escrow');
    section && section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <div>
      <div>
        <div className="flex items-center">
          <button
            className="px-[30px] py-[5px] bg-[#FCF952] flex justify-center items-center text-black font-bold"
            onClick={() => {
              setMode('DEFAULT');
              setFn(oldAssets);
            }}
          >
            <LeftCircleOutlined className="mr-[5px] mt-[2px]" />
            Back
          </button>
          <div
            className="font-bold text-[30px] ml-[20px] text-[#FCF952]"
            id="select"
          >
            {`SELECT NFTs (${selectedAssets.length}/5)`}
          </div>
        </div>
        <div className="text-white text-[18px] mb-[20px]">
          Add at least 1 NFT and/or ERC20 Token to the SWAP requirement.
        </div>
      </div>
      <div className="bg-[#FCF952] p-[20px] flex flex-col justify-center">
        <ul className="flex flex-wrap justify-between">
          {!_.isEmpty(assets) &&
            assets.map((asset) => {
              const {
                image_preview_url,
                id,
                token_id,
                asset_contract: { address, schema_name },
                collection: {
                  name: collectionName,
                  image_url: collectionImgUrl,
                },
              } = asset;

              const imgUrl = image_preview_url || collectionImgUrl;
              const isCollectionVerified =
                asset.collection.safelist_request_status === 'verified';
              const isSelected = selectedAssets.find((a) => {
                return a.tokenId === token_id && a.address === address;
              });

              return (
                <li
                  className={`relative w-[240px] h-[255px] cursor-pointer my-[10px] ${
                    isSelected && 'border-4 border-[#C3C002]'
                  }`}
                  key={id}
                  onClick={() => {
                    return onNFTClicked({
                      tokenId: token_id,
                      address: address,
                      amount: 1,
                      tokenType: schema_name,
                      imgUrl,
                      collectionName,
                      oldAssetsState: selectedAssets,
                      setFn,
                    });
                  }}
                >
                  {isSelected && (
                    <div className="absolute right-[10px] top-[8px] bg-[#C3C002] w-[20px] h-[20px] flex justify-center items-center">
                      <CloseOutlined className="text-white text-[12px]" />
                    </div>
                  )}
                  <img
                    alt={`${collectionName} #${token_id}`}
                    src={imgUrl}
                    className="h-[190px] w-[240px]"
                  />
                  <div className="bg-white p-[5px] h-[55px] flex flex-col items-center justify-center">
                    <div className="font-bold text-[16px] truncate text-black max-w-[200px]">{`#${token_id}`}</div>
                    <Tooltip
                      placement="top"
                      title={
                        isCollectionVerified
                          ? 'Collection verified on OpenSea'
                          : 'Collection not verified on OpenSea'
                      }
                    >
                      <div className="text-[14px] text-[#B8B500] truncate w-full text-center">
                        {collectionName}{' '}
                        {isCollectionVerified ? (
                          <CheckCircleOutlined />
                        ) : (
                          <WarningOutlined />
                        )}
                      </div>
                    </Tooltip>
                  </div>
                </li>
              );
            })}
        </ul>
        <div className="flex justify-center mt-[40px]">
          <button
            className="bg-black font-bold text-[#FCF952] text-[18px] px-[40px] py-[10px]"
            onClick={() => setMode('DEFAULT')}
          >
            Save & Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default AssetSelection;
