import envVars from 'config';

const {
  contract: { wethTokenAddress, usdtTokenAddress, lithTokenAddress },
} = envVars;

export const TOKEN_TYPE = {
  ERC20: 0,
  ERC721: 1,
  ERC1155: 2,
};

export const SUPPORTED_TOKEN_ADDRESSES = [
  {
    label: 'WETH',
    value: wethTokenAddress,
  },
  {
    label: 'USDT',
    value: usdtTokenAddress,
  },
  {
    label: 'LITH',
    value: lithTokenAddress,
  },
];

export const SUPPORTED_NFT_ADDRESSES = [];

export const ESCROW_CONTRACT_AGREEMENT_TYPE = {
  TradeInfo: [
    { name: 'trader', type: 'address' },
    { name: 'tokenType', type: 'uint8[]' },
    { name: 'tokenAddress', type: 'address[]' },
    { name: 'tokenId', type: 'uint256[]' },
    { name: 'tokenAmount', type: 'uint256[]' },
  ],
  Agreement: [
    { name: 'nonce', type: 'uint256' },
    { name: 'signerSide', type: 'TradeInfo' },
    { name: 'executorSide', type: 'TradeInfo' },
    { name: 'expiryTimestamp', type: 'uint256' },
  ],
};
