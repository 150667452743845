import { createContext, useEffect, useMemo, useState, ReactNode } from 'react';
import { node } from 'prop-types';

const WindowSizeContext = createContext({});

interface StateI {
  width: number;
  height: number;
}

export function WindowSizeContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [windowSize, setWindowSize] = useState<StateI>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { width, height } = windowSize;

  const value = useMemo(
    () => ({
      width,
      height,
      xs: width < 640,
      sm: width >= 640 && width < 768,
      md: width >= 768 && width < 1024,
      lg: width >= 1024 && width < 1280,
      xl: width >= 1280 && width < 1536,
      xl2: width >= 1536,
    }),
    [windowSize]
  );

  return (
    <WindowSizeContext.Provider value={value}>
      {children}
    </WindowSizeContext.Provider>
  );
}

WindowSizeContextProvider.propTypes = {
  children: node,
};

export default WindowSizeContext;
